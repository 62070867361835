import {
  IonCheckbox,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonList,
  IonPopover,
} from "@ionic/react";
import {
  mailOutline,
  shareSocialOutline,
} from "ionicons/icons";
import { ClientData } from "../../utils/interfaces"; 
import { useState } from "react";

const ShareSocial = (props: { client: ClientData }) => {
  const client = props.client;
  const baseUr = "https://app.sizewall.com/dashboard/";
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [shareEnabled, setShareEnabled] = useState(false);
  const mailtoLink = () => {
    return `mailto:${client.email}?subject=Your%20results&body=Hi%20${client.firstName}%2C%0D%0A%0D%0AHere%20is%20the%20link%20to%20your%20results%3A%20${baseUr}${client.ID}%0D%0A%0D%0AKind%20regards%2C%0D%0A${client.trainer}`;
  }
  return (
    <>
    <IonFab vertical="bottom" horizontal="start" >
        <IonFabButton onClick={() => setIsOpenShare(true)}> <IonIcon icon={shareSocialOutline}></IonIcon> </IonFabButton>
    </IonFab>
    <IonPopover
          isOpen={isOpenShare}
          onDidDismiss={() => setIsOpenShare(false)}
          side="top"
          alignment="start"
        >
          <IonContent>
            <IonList>
              <IonItem detail={false}>
                Enable sharing
                <IonCheckbox
                  slot="end"
                  onIonChange={(e) => {
                    setShareEnabled(e.detail.checked);
                  }}
                />
          
              </IonItem>
              <IonItem button={true} disabled={!shareEnabled} href={mailtoLink()} >
                 Email <IonIcon slot="end" icon={mailOutline}></IonIcon>
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>

    </>
  );
};
export default ShareSocial;
