import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Auth0Provider } from '@auth0/auth0-react';
// Call the element loader after the platform has been bootstrapped
//defineCustomElements(window);

const hostnames = ["localhost1","192"]
ReactDOM.render(
  !hostnames.some(cond => window.location.hostname.includes(cond)) ?
  <React.StrictMode>
    
    <Auth0Provider
    domain="dev-s4l4fwvt05o1jmep.eu.auth0.com"
    clientId="vgN35I6xG7uATYnQ5ExDSv6GpXdkx5VS"
    cacheLocation='localstorage'
    authorizationParams={{
        scope: 'openid profile email',
        audience: 'https://sizewall-function-api.azurewebsites.net/',
        redirect_uri: window.location.origin
        
      
    }}
  >
    <App />
     </Auth0Provider>
   </React.StrictMode>:<React.StrictMode><App></App></React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
