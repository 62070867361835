import React, { useState } from "react";
import { IonInput } from "@ionic/react";
export interface FromValProps {
  validate: (
    value: string  
  ) => boolean;
  setIsValid: (name: string, valid: boolean) => void;
  setValue: (name: string, value: string | number | undefined | null) => void;
  errorText: string;
  helperText: string;
  name: string;
  label: string;
  autofocus?: boolean;
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url' | 'search' | 'time' | 'date' | 'month' | 'week' | 'datetime-local' | 'number' | undefined;
}

function InputValidation(props: FromValProps) {
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();
  const setValid = (valid: boolean) => {
    setIsValid(valid);
    props.setIsValid(props.name, valid);
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setIsValid(undefined);
    if (value === "") return false;
    props.validate(value)
      ? setValid(true)
      : setValid(false);
  };

  const markTouched = () => {
    setIsTouched(!isTouched);
  };

  return (
    <IonInput
      className={`${isValid && "ion-valid"} ${
        isValid === false && "ion-invalid"
      } ${isTouched && "ion-touched"}`}
      type={props.type}
       autofocus={props.autofocus}
      //fill="solid"
      label={props.label}
      labelPlacement="floating"
      //helperText={isTouched? props.helperText : ''}
      errorText={isValid === false ? props.errorText : undefined}
      onIonInput={(event) => validate(event)}
      onIonChange={(e) => {
        const target = e.target as HTMLIonInputElement;
        props.setValue(props.name, target.value);
      }}
      onIonBlur={() => markTouched()}
    ></IonInput>
  );
}
export default InputValidation;
