import {
	IonContent,
	IonGrid,
	IonPage,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "../theme/Results.css";

import "../theme/Utilities.css";
import { useParams } from "react-router";
import { MeasurementResource, ObjectModel } from "../common/types";
import MeasurmentResult from "../components/MeasurementsResults";
import Toolbar from "../components/Toolbar";
import Store from "../components/StoreModel";
const ResultPage: React.FC = () => {
	const { id } = useParams<{ id: string }>()
    const [subtitle, setSubtitle]= useState<string>("Results")
	const [isFetched, setIsFetched] = useState(false);
	const [objectModel, setObjectModel] = useState<ObjectModel>({
		id: "",
		createdAt: "",
		obj: "",
		visibleEffects: [0],
        showMeasurement: "",
		measurements: {
			id: "",
			createdAt: "",
			height: 0,
			head: 0,
			neck: 0,
			chest: 0,
			waist: 0,
			buttocks: 0,
			leg_inner: 0,
			arm_outer: 0
		}
	});

	const fetchModel = async () => {
        const model = await Store().get(id)
		var objModel = {
			id: id,
			createdAt: model?.createdAt as string,
			obj: model?.objectModel as string,
			measurements: model?.measurements as MeasurementResource
		} as ObjectModel
		if (objModel.obj === undefined) {
			objModel.obj = ""
		}
        setSubtitle(model?.name as string)
        console.log("objModel: name ", model?.name)
		setObjectModel({ ...objectModel, ...objModel })
	};

	useEffect(() => {
		if (objectModel.obj !== "") {
			setIsFetched(true)
		}
	}, [isFetched, objectModel])

	useEffect(() => {
		fetchModel()
	}, [isFetched]);

	
		return (
			<IonPage>
                <Toolbar subHeader={subtitle}></Toolbar>
				{isFetched?<IonContent>
					<IonGrid className="contentGrid">
                        <MeasurmentResult objectModels={[objectModel]} measurementList={[objectModel.measurements]}/>
					</IonGrid>
				</IonContent>:<IonContent>
					<div >
						<div >
							<IonSpinner name="lines-sharp"></IonSpinner>
						</div>
						<br></br>
						<br></br>
						<IonText  color="light">
							<h1>Please wait...</h1>
						</IonText>
					</div>
				</IonContent>}
			</IonPage>
		);

};

export default ResultPage;
