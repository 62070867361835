import {
  IonCard,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import { useParams } from "react-router";
import {
  DashBoardComponentProps,
} from "../data/data";

import { useEffect, useState } from "react";
import ShareSocial from "./ShareSocial";
import { ClientData } from "../../utils/interfaces";
import { ProfileImage } from "./ProfileImage";
import { Loading } from "./Loading";
import NewMeasureTab from "./NewMeasureTab";

export const HomeTab: React.FC<DashBoardComponentProps> = ({
  clientHolder,
}) => {
  const id = useParams<{ id: string }>().id;
  const [clientData, setClientData] = useState<ClientData>();
  const [weight, setWeight] = useState<number>();
  async function fetchData() {
    setClientData(await clientHolder.getClient());
    const measurements = await clientHolder.getMeasurements();
    const weights = measurements
      .map((m) => {
        return { val: m.measurements.weight, date: m.createdAt };
      })
      .filter((m) => m.val !== undefined)
      .sort((a, b) => (a.date > b.date ? -1 : 1)).map(m => m.val);
    if (weights.length > 0) {
      setWeight(weights[0]);
    }
  }
  useEffect(() => {
    fetchData();
  }, [id]);
  return (
    <>
      <IonCard>
        {clientData ? (
          <IonList>
            <IonItem>
              <IonLabel>
                {clientData.firstName} {clientData.lastName}
              </IonLabel>
              <ProfileImage
                profileImage={clientData.profileImage}
                slot={"end"}
              ></ProfileImage>
            </IonItem>
            <IonItem>
              <IonLabel>Age</IonLabel>
              <IonText>{clientData.age}</IonText>
            </IonItem>
            <IonItem>
              <IonLabel>Height</IonLabel>
              <IonText>{clientData.height}</IonText>
            </IonItem>
            <IonItem>
              <IonLabel>Weight</IonLabel>
              <IonText>{weight}</IonText>
            </IonItem>
            <IonContent className="h-32">
      
              <ShareSocial client={clientData}></ShareSocial>
              <NewMeasureTab client={clientData} clientHolder={clientHolder}></NewMeasureTab>
            </IonContent>
          </IonList>
        ) : (
          <Loading></Loading>
        )}
      </IonCard>
      {clientData && <></> }
    </>
  );
};

export default HomeTab;
