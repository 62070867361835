import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { cameraOutline } from "ionicons/icons";
import image from "../assets/Instructions/FrontPoseInstructions.png";
import "../theme/Instructions.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { Granted, Permissions } from "../components/Permissions";
const FrontPosePage: React.FC = () => {
  const { id, gender } = useParams<{ id: string; gender: string }>();
  const [permission, setPermission] = useState(false);
  useEffect(() => {
    }
    , [permission]);

  return (
    <IonPage>
      <IonContent>
        <Permissions
          updated={(granted: Granted) => setPermission(granted.gyro)}
        ></Permissions>
        {permission && (
          <>
          
            <div className="justify-items-center w-[95%] max-h[80%] mx-[2%] mt-[15%]">
              <img src={image} alt="Image showing person in A pose" />
            </div>

            <div className="buttonsUnderInstructions">
              <IonButton
                size="large"
                fill="outline"
                routerLink={`/photo/${id}/${gender}/front`}
              >
                Open camera <IonIcon icon={cameraOutline} />
              </IonButton>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default FrontPosePage;
