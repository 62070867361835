import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { OBJLoader2 } from "wwobjloader2";
import { measurements, MeasurePoint } from "./measurePoints";
import { useThree } from "@react-three/fiber";
import { Mesh, MeshPhongMaterial } from "three";
import { OrbitControls } from "@react-three/drei";
import { ObjectModel } from "../common/types";
import ConvexHullGrahamScan from "./graham";
interface MeasurePoints3D {
  name: string;
  points: THREE.Vector3[];
}
interface ObjectModelProps {
  models: ObjectModel[];
  showMeasurement: string;
  activeModel: number;
}



const ThreeDModel: React.FC<ObjectModelProps> = ({
  models,
  activeModel,
  showMeasurement,
}) => {
  const scene = new THREE.Scene();

  const ref = useRef<Mesh>(null!);
  const [visual, setVisual] = useState<THREE.Group>(new THREE.Group());
  const [mesh, setMesh] = useState<THREE.Mesh>(new THREE.Mesh());
  const [obj, setObj] = useState<THREE.Object3D>(new THREE.Object3D());
  const [objs, setObjs] = useState<THREE.Object3D[]>([]);
  const [measurePoints, setMeasurePoints] = useState<MeasurePoints3D[]>([]);

  const objLoader2 = new OBJLoader2();
  objLoader2.setUseIndices(true);
  const pointGeometry = new THREE.SphereBufferGeometry(0.01);
  const get_vertexpos = function (v: number, mesh: THREE.Mesh) {
    var vertexpos = mesh.geometry.attributes.position;
    const offset = 0
    let s = vertexpos.itemSize;
    let x = v * s + offset;
    let verts = vertexpos.array;
    let res = new THREE.Vector3(verts[x], verts[x + 1], verts[x + 2]);
    return res;
  };
  function addScenePoint(pointI: THREE.Vector3, size?: number, color?: number) {

    var pointMaterial = new THREE.MeshBasicMaterial({
      color: color ? color : 0xfcf9a2,
    });
    var pointM = new THREE.Mesh(pointGeometry, pointMaterial);
    var point = pointI.clone();
    pointM.position.set(point.x, point.y, point.z);
    point.setScalar(size ? size : 2);
    visual.add(pointM);
  }
  interface Plane {
    normal: THREE.Vector3;
    offset: number;
  }
  function addPlane(somePlane:Plane, color = 0xff0000) {
	const plane = new THREE.Plane(somePlane.normal, - somePlane.offset);
	const helper = new THREE.PlaneHelper(plane, 1, color);
	visual.add(helper);
    
	return plane;
}
  
  


  let planeFromPoints = function (plane: number[],verts:number[],  mesh: THREE.Mesh, name?: string) {
    var plane_a = get_vertexpos(plane[0], mesh);
    var plane_b = get_vertexpos(plane[1], mesh);
    var plane_c = get_vertexpos(plane[2], mesh);
    let normal = new THREE.Vector3().crossVectors(
      new THREE.Vector3().subVectors(plane_a, plane_b),
      new THREE.Vector3().subVectors(plane_a, plane_c)
    ).normalize();

    var offset = -normal.dot(plane_a);
    //offset = offset;
    const calculated = {
      normal: normal,
      offset: offset,
    };
    
    return calculated;
  };
  const getPlaneNormal = function (mesh: THREE.Mesh) {
    let avg = new THREE.Vector3();
    var pointMap = {
      heels: {
        vertices: [3466, 6867],
      },
    };
    let length = pointMap.heels.vertices.length;

    pointMap.heels.vertices.forEach(function (v) {
      avg = avg.add(get_vertexpos(v, mesh));
    });

    avg = avg.divideScalar(length);

    let head = get_vertexpos(414, mesh);

    return head.sub(avg).normalize();
  };
  interface Plane {
    normal: THREE.Vector3;
    offset: number;
  }
  const getMeasurePointsSimple = function (mp: MeasurePoint, mesh: THREE.Mesh) {
    const pts: THREE.Vector3[] = [];
    if (mp.vertices != null) {
      let plane = null;
      if (mp.plane != null) {
        plane = planeFromPoints(mp.plane,mp.vertices, mesh);

        //addPlane(plane);
      }
      let vpPrev = null;
      for (const element of mp.vertices) {
        let vertex = element;
        //mm.vertices.push(vertex)

        var vp = get_vertexpos(vertex, mesh);
       /* if (plane != null) {
          vp.sub(plane.normal.clone().multiplyScalar(-plane.offset));
        }  */
        pts.push(vp);
      }
    }
    return pts;
  }


 
  const getMeasurePoints = function (mp: MeasurePoint, mesh: THREE.Mesh) {
    const pts: THREE.Vector3[] = [];
    if (mp.vertices != null) {
      var convexHull = null;
      var plane_origin = null;
      var tangent_x = null;
      var tangent_y = null;
      //plane=null;
      var plane = null;
      if (
        mp.usePlaneNormal &&
        mp.vertices &&
        mp.vertices.length > 2 &&
        mp.plane == null
      ) {
        const indis = [
          0,
          Math.round(mp.vertices.length / 3),
          Math.round((mp.vertices.length / 3) * 2),
        ];
        mp.plane = [
          mp.vertices[indis[0]],
          mp.vertices[indis[1]],
          mp.vertices[indis[2]],
        ]; 

        plane = planeFromPoints(mp.plane,mp.vertices, mesh, mp.name);
      } else if (mp.plane != null) {
        plane = planeFromPoints(mp.plane,mp.vertices, mesh, mp.name);
      }

      if (plane != null && mp.plane != null) {
        plane_origin = plane.normal.clone().multiplyScalar(-plane.offset);
        //addPlane(plane, 0x0000ff);
        if (mp.convex_hull) {
          let pointinPlane = get_vertexpos(mp.plane[1], mesh);
          tangent_x = new THREE.Vector3()
            .subVectors(pointinPlane, plane_origin)
            .normalize();
          tangent_y = new THREE.Vector3()
            .crossVectors(plane.normal, tangent_x)
            .normalize();
          convexHull = new ConvexHullGrahamScan();
        }
      }
      var vpPrev = null;
      for (const element of mp.vertices) {
        var vertex = element;
        //mm.vertices.push(vertex)

        var vp = get_vertexpos(vertex, mesh);
        if (
          convexHull != null &&
          plane_origin != null &&
          tangent_x != null &&
          tangent_y
        ) {
          vp.sub(plane_origin);
          let x = vp.dot(tangent_x);
          let y = vp.dot(tangent_y);
          convexHull.addPoint(x, y);
        } else {
          if (plane != null && plane_origin != null) {
            vp.sub(plane_origin).projectOnPlane(plane.normal).add(plane_origin);
          }
          pts.push(vp);
        }
      }
      if (
        convexHull != null &&
        plane_origin != null &&
        tangent_x != null &&
        tangent_y != null
      ) {
        let hull = convexHull.getHull();
        
        hull.push(hull[0]);
        for (var j = 0; j < hull.length; j++) {
          let x = hull[j].x;
          let y = hull[j].y;
          let vp = plane_origin
            .clone()
            .add(tangent_x.clone().multiplyScalar(x))
            .add(tangent_y.clone().multiplyScalar(y));
          pts.push(vp);
        }
      }
    }
    return pts;
  };

  enum Direction {
    OUT = 1,
    OUT_Y = 2,
    IN_Y = 3,
    NONE,
  }
  function addLine(
    pointA: THREE.Vector3,
    pointB: THREE.Vector3,
    visual: THREE.Group,
    color?: number,
    direction?: Direction
  ) {
    const material = new LineMaterial({
      color: color ? color : 0xfcf9a2,
      linewidth: 0.01,
      dashed: false,
    });
    //material.color = new THREE.Color(color?color:0xfcf9a2);

    const geometry = new LineGeometry();

    var line = new Line2(geometry, material);
    var line2 = new Line2(new LineGeometry(), material);
    line2.geometry.setPositions([
      pointA.x,
      pointA.y,
      pointA.z,
      pointB.x,
      pointB.y,
      pointB.z,
    ]);
    const planeNormal = getPlaneNormal(mesh);
    let moveBy = -0.01;

    const moveDirectionA = pointA
      .clone()
      .projectOnVector(planeNormal)
      .sub(pointA)
      .normalize()
      .multiplyScalar(moveBy);
    const moveDirectionB = pointB
      .clone()
      .projectOnVector(planeNormal)
      .sub(pointB)
      .normalize()
      .multiplyScalar(moveBy);
    if (direction === Direction.OUT) {
      const pA = pointA.clone().add(moveDirectionA);
      const pB = pointB.clone().add(moveDirectionB);
      line.geometry.setPositions([pA.x, pA.y, pA.z, pB.x, pB.y, pB.z]);
      line2.geometry.setPositions([
        pointA.x,
        pointA.y,
        pointA.z,
        pointB.x,
        pointB.y,
        pointB.z,
      ]);
    } else if (direction === Direction.OUT_Y || direction === Direction.IN_Y) {
      moveBy = 0.015 * (direction === Direction.OUT_Y ? 1 : -1);
      const pA = pointA
        .clone()
        .add(moveDirectionA.normalize().multiplyScalar(moveBy));
      const pB = pointB
        .clone()
        .add(moveDirectionB.normalize().multiplyScalar(moveBy));
      line.geometry.setPositions([pA.x, pA.y, pA.z, pB.x, pB.y, pB.z]);
    }
    if (direction !== Direction.NONE) {
      line.computeLineDistances();
      visual.add(line);
    } else {
      line2.computeLineDistances();
      visual.add(line2);
    }
  }

  function loadModel(model: ObjectModel) {
    visual.clear();

    const prom = new Promise<THREE.Object3D>((resolve, reject) => {
      const obj2 = objLoader2.load(
        "data:model/obj;base64," + model.obj,
        function (object: THREE.Object3D) {
          setObj(object);
          object.traverse(function (child) {
            if (child instanceof THREE.Mesh || child.type === "Mesh") {
              const meshChild = child as THREE.Mesh;
              if (6890 === meshChild.geometry.attributes.position.count) {
                setMesh(meshChild);
                meshChild.material = material;

                setMeasurePoints(
                  measurements.map((measurePoint: MeasurePoint) => {
                    const points = getMeasurePoints(measurePoint, meshChild);
                    return { name: measurePoint.name, points: points };
                  })
                );

                resolve(object as THREE.Object3D);
              } else {
                return;
              }
            }
          });
        }
      );
    });
    return prom;
  }
  // model/obj or image/png; both works, probably others as well

  const axesHelper = new THREE.AxesHelper(5);
  //scene.add( axesHelper );

  const camera = useThree((state) => state.camera);

  camera.traverse((child) => {
    if (child instanceof THREE.PointLight) {
      camera.remove(child);
    }
  });
  scene.add(camera);

  const material = new MeshPhongMaterial({
    color: 0xffffff,
    wireframe: false,
    flatShading: false,
    opacity: 0.4,
  });

  const showObject = async () => {
    visual.clear();
    if (obj) {
      obj.rotation.x = Math.PI;
      obj.rotation.y = Math.PI;
      //obj.remove(visual);
      obj.traverse((child) => {
        if (child instanceof Mesh) {
          const meshChild = child as Mesh;
        }
      });

      obj.add(visual);
      scene.add(obj);
    }
  };
  useEffect(() => {
    loadModel(models[activeModel]);
    visualByName(showMeasurement);
  }, [objs, activeModel, showMeasurement]);
  useEffect(() => {
    showObject();
  }, [obj]);

  useEffect(() => {
    visualByName(showMeasurement);
  }, [showMeasurement, obj]);

  const light = new THREE.PointLight(0xffffff, 0.35);
  camera.add(light);

  
  const visualByName = (name: string) => {
    let points = measurePoints.find(
      (measurePoint) => measurePoint.name === name
    );
    if (points) {
      for (let i = 1; i < points?.points.length; i++) {
        let dir = Direction.NONE;

        if (name === "leg_inner") {
          dir = Direction.IN_Y;
        } else if (
          name === "arm_outer" ||
          name === "chest" ||
          name === "waist" ||
          name === "buttocks"
        ) {
          dir = Direction.NONE;
        }

        addLine(
          points?.points[i - 1],
          points?.points[i],
          visual,
          0x196e44,
          dir
        );
      }
    }
  };
  return (
    //returning 3D model object + scene (on canvas)
    <>
      <primitive object={scene} ref={ref} />
      <OrbitControls
        onChange={(e: THREE.Event | undefined) => {
          //onOrbitControlChange(e);
        }}
      />
    </>
  );
};

export default ThreeDModel;
