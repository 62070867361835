import { IonButton, IonHeader, IonText } from "@ionic/react";
import { useEffect, useState } from "react";

// Buttons to grant permissions camera and gyroscope
export interface Granted {
  gyro: boolean;
}

interface PermissionsProps {
  updated: (granted: Granted) => void;
}

export const Permissions: React.FC<PermissionsProps> = ({ updated }) => {
  const [granted, setGranted] = useState<Granted>({
    gyro: false,
  });
  const [gyro, setGyro] = useState(false);
  const [denied, setDenied] = useState(false);
  const [showGyro, setShowGyro] = useState(false);
  const message = `Oops, you may have clicked deny previously to reset please clear website data \n\n Open settings for you phone then go to \n\n Safari -{">"} Advanced -{">"} Website data \n\n Clear data for ${window.location.hostname} `;

  const checkGyroAlreadyGranted = async () => {
    if (gyro === true) {
      return;
    }
    await requestGyro();
  };

  const requestGyro = async () => {
    try {
      console.log("Requesting permission");
      // If the device supports the DeviceOrientationEvent and the requestPermission function, ie IOS 13+ devices
      if (
        DeviceOrientationEvent !== undefined &&
        typeof (DeviceOrientationEvent as any).requestPermission === "function"
      ) {
        console.log("Requested permission");

        const permission = await (DeviceMotionEvent as any).requestPermission();
        console.log("Requested permission");

        if (permission === "granted") {
          setGyro(true);
          setGranted({ ...granted, gyro: true });
          setShowGyro(false);
          updated({ ...granted, gyro: true });
        } else {
            alert(message)
          //alert("Permission denied")
          setDenied(true);
          setGyro(false);
          setGranted({ ...granted, gyro: false });
          setShowGyro(true);
        }
      }else{
           // ON android and laptops etc 
            setDenied(false);
            setGyro(true);
            setGranted({ ...granted, gyro: true });
            setShowGyro(false);
        }
    } catch (e) {
      // alert("Error requesting permission"+ e)
      console.log("Requested permission");

      setGyro(false);
      setGranted({ ...granted, gyro: false });
      setShowGyro(true);
    }
  };

  useEffect(() => {
    if (!gyro) {
      checkGyroAlreadyGranted();
    } else {
      updated({ ...granted, gyro: true });
    }
  }, [gyro]);
  if (!showGyro) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-200 bg-opacity-90 flex items-center justify-center">
      <div className="bg-white w-[90%] h-[90%] rounded-lg flex flex-col items-center justify-center">
        {showGyro && (
          <div className="flex flex-col items-center justify-center">
            {denied && (
              <IonText color={"danger"} className="m-2">
                {" "}
                {message.replace('\n',"<br/>")}{" "}
              </IonText>
            )}
            {!denied && (
              <IonText color={"primary"} className="m-2">
                Dear Iphone user, for best results we want to use the gyroscope
                sensor on your phone, please click the button and then click
                accept.
              </IonText>
            )}{" "}
            <IonButton
              size="large"
              fill="outline"
              disabled={denied}
              onClick={requestGyro}
            >
              Grant Permission
            </IonButton>
          </div>
        )}
      </div>
    </div>
  );
};
