import "../theme/Webcam.css";

import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Camera from "react-webcam";
import { IonSelect, IonSelectOption } from "@ionic/react";
import DeviceOrientation from "../components/DeviceOrientation";
import { Context } from "../MyContext";
import { CircularTimer } from "./CircularTimer";
import { MediaPipe } from "./MediaPipe";
import { LoadingAnimation } from "./LoadingAnimation";
import { useLocation } from "react-router";

type FacingMode = "user" | "environment";

interface CameraOverlayProps {
  id: string;
  pose: "front" | "right";
  gender: string;
  uploadPhoto: Function;
  setUploaded: Function;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  z-index: 1001;

  @media (max-aspect-ratio: 2/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 3;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;
const maxWidth = 768;

const TimerButton = styled(Button)`
  background: url("https://img.icons8.com/ios/50/000000/timer.png");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  @media (max-width: ${maxWidth}px) {
    &:disabled {
      background-color: rgba(128, 128, 128, 0.5);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const TakePhotoButton = styled(Button)`
  background: url("https://img.icons8.com/ios/50/000000/compact-camera.png");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  @media (max-width: ${maxWidth}px) {
    &:disabled {
      background-color: rgba(128, 128, 128, 0.5);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const UploadButton = styled(Button)`
  background: url("https://img.icons8.com/ios/50/upload-to-ftp.png");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const WebcamContainer = styled.div`
  width: 100%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > video {
    width: auto;
    height: 100%;
    max-width: none;
  }
`;
const getFacingMode = (label: string): FacingMode | undefined => {
  if (
    label.toLowerCase().includes("front") ||
    label.toLowerCase().includes("fram") ||
    label.toLowerCase().includes("face")
  ) {
    return "user";
  } else if (
    label.toLowerCase().includes("back") ||
    label.toLowerCase().includes("bak")
  ) {
    return "environment";
  }
  return undefined;
};
const getFacingFromStorage = (): FacingMode => {
  const mode = window.localStorage.getItem("facing_mode");
  if (mode === "user" || mode === "environment") {
    return mode;
  }
  return "user";
};
const shouldRunPoses = {} as { [key: string]: boolean };
const setShouldRunPoses = (poses: { [key: string]: boolean }) => {
  Object.keys(poses).forEach((key) => {
    shouldRunPoses[key] = poses[key];
  });
};

export const CameraOverlay = (props: CameraOverlayProps) => {
  const fileInput = useRef(null);

  const { orientation } = useContext(Context);
  const [facingMode, setFacingMode] = useState<FacingMode>("user");
  const tolerance = 5;
  const [time, setTime] = useState(10);
  const [constraints, setConstraints] = useState<MediaTrackConstraints>({
    width: { ideal: 1024 },
  });
  const [mirrored, setMirrored] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= maxWidth);
  const camera = useRef<any>(null);
  const [isPoseOK, setIsPoseOK] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [poseLoaded, setPoseLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  //const [shouldRunPoses, setShouldRunPoses] = useState<{[key: string]: boolean}>({});
  const currentId = `${props.id}-${props.pose}`;

  useEffect(() => {
    setShouldRunPoses({ [currentId]: true });
  }, [location]);
  // state dict string -> boolean

  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    window.localStorage.getItem("CameraDeviceId") || undefined
  );
  const shouldRunPose = (id: string, log?: boolean) => {
    const res = shouldRunPoses[id] || false;
    if (log) {
      console.log("should run", id, res);
      console.log("should run", shouldRunPoses);
    }

    return res;
  };
  const setRunPose = (run: boolean) => {
    setShouldRunPoses({ ...shouldRunPoses, [currentId]: run });
  };
  useEffect(() => {
    console.log("run pos", shouldRunPoses);
  }, [shouldRunPoses]);

  useEffect(() => {
    if (activeDeviceId && devices.length > 0 && devices[0].deviceId) {
      const device = devices.find((d) => d.deviceId === activeDeviceId);
      const facing = getFacingMode(device?.label || "");
      if (facing !== undefined) {
        console.log("facing", facing);
        setFacingMode(facing);
      }
      setMirrored(facing === "user");

      setConstraints({ ...constraints, deviceId: { exact: activeDeviceId } });
    }
  }, [activeDeviceId, devices]);

  useEffect(() => {
    if (!facingMode) {
      return;
    }
    setMirrored(facingMode === "user");
    window.localStorage.setItem("facing_mode", facingMode);
  }, [facingMode]);

  useEffect(() => {
    if (time <= 0) {
      clearInterval(intervalId!);
      setIsVisible(false);
      takePhoto(0);
    }
  }, [time]);

  useEffect(() => {
    if (duration <= 0) {
      return;
    }
    const interval = setInterval(() => {
      setTime((prev) => prev - 0.25);
      setProgress((prev) => prev + 1 / (duration * 4));
    }, 250);
    setIntervalId(interval);
    setIsVisible(true);
    return () => clearInterval(interval);
  }, [duration]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= maxWidth);
    };

    window.addEventListener("resize", handleResize);

    // Kör en gång vid montering för att sätta initialt värde
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const poseOKCallback = (ok: boolean) => {
    if (ok) {
      setIsPoseOK(true);
      if (facingMode === "user") {
        setRunPose(false);
        takePhoto(0);
      } else {
      }
    } else {
      setIsPoseOK(false);
    }
  };
  const takePhoto = async (timeout: number) => {
    setRunPose(false);
    if (timeout > 0) {
      setDuration(timeout);
      return;
    }

    if (camera.current) {
      const photo = camera.current.getScreenshot();
      setRunPose(false);
      props.uploadPhoto(photo, orientation);
    }
  };

  useEffect(() => {
    if (activeDeviceId && devices.length > 0) {
      const device = devices.find((d) => d.deviceId === activeDeviceId);
      const facing = getFacingMode(device?.label || "");
      if (facing !== undefined) {
        setFacingMode(facing);
      }
    }
  }, [activeDeviceId, devices]);
  const [camerasLoaded, setCamerasLoaded] = useState(false);
  const selectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const photo = e.target?.result?.toString();
      if (photo) {
        setRunPose(false);
        props.uploadPhoto(photo, orientation);
      }
    };
    fileReader.readAsDataURL(event.target.files?.[0] as Blob);
  };

  const loadCameras = async (force = false) => {
    if (camerasLoaded && devices.length > 0 && !force && !isLoading) {
      return;
    }
    await navigator.mediaDevices.enumerateDevices();
    const allDevices = await navigator.mediaDevices.enumerateDevices();

    const videoDevices = allDevices.filter((i) => i.kind === "videoinput");
    setDevices(videoDevices);

    const deviceId =
      window.localStorage.getItem("CameraDeviceId") || activeDeviceId;
    if (
      deviceId &&
      videoDevices.length > 0 &&
      videoDevices.find((d) => d.deviceId === activeDeviceId)
    ) {
      setConstraints({ ...constraints, deviceId: { exact: activeDeviceId } });
    }
    setCamerasLoaded(videoDevices.length > 0);
    return;
  };
  useEffect(() => {
    (async () => {
      await loadCameras(true);
      setIsLoading(false);
    })();
  }, []);

  if (!camerasLoaded || isLoading) {
    return (
      <Wrapper>
        <div>Loading cameras</div>
      </Wrapper>
    );
  }

  function isDisabled(): boolean | undefined {
    if (!isMobile) {
      return false;
    }
    if (!orientation || !orientation.beta || orientation.beta === 0) {
      return false;
    }
    if (!isPoseOK) {
      return true;
    }
    return Math.abs(orientation.beta - 90) > tolerance;
  }

  return (
    <>
      <Wrapper>
        <WebcamContainer>
          {
            <Camera
              minScreenshotWidth={1024}
              mirrored={mirrored}
              ref={camera}
              onUserMedia={async () => {
                await loadCameras(true);
              }}
              onUserMediaError={(error) => alert(error)}
              videoConstraints={constraints}
            />
          }
          <div
            className="overlay-container"
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "100%",
              top: "0%",
              left: "0%",
            }}
          >
            <CircularTimer
              duration={duration}
              progress={progress}
              visible={isVisible}
              onComplete={() => {}}
            >
              {" "}
            </CircularTimer>
            {!poseLoaded && (
              <LoadingAnimation
                color="white"
                size={200}
                speed={10}
              ></LoadingAnimation>
            )}
            {/* <img className="overlay" src={overlay_image[props.pose + "_" + props.gender]} style={{ zIndex: 1000, maxHeight: "80%", aspectRatio: 'preserve', justifyContent: 'center', opacity: '50%' }} />
             */}{" "}
          </div>
          <DeviceOrientation />
        </WebcamContainer>

        <div className="motionInfo"></div>

        <Control>
          <ChangeFacingCameraButton
            onClick={async () => {
              await loadCameras(true);

              document.getElementById("selectCamera")?.click();
            }}
          />
          {camerasLoaded && (
            <IonSelect
              hidden
              placeholder="Select camera"
              title="Select camera"
              id="selectCamera"
              onIonChange={(details) => {
                const [deviceId, label] = details.detail.value.split(";;");
                const facing = getFacingMode(label);

                // Store deviceId in a cookie
                window.localStorage.setItem("CameraDeviceId", deviceId);
                if (facing) {
                  setFacingMode(facing);
                }
                setConstraints({
                  ...constraints,
                  deviceId: { exact: deviceId },
                });
                setActiveDeviceId(deviceId);
              }}
            >
              {devices.map((d) => (
                <IonSelectOption
                  key={d.deviceId}
                  value={d.deviceId + ";;" + d.label}
                >
                  {d.label}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
          <div style={{ zIndex: 3 }}>
            <input
              ref={fileInput}
              id="fileInput.upload"
              hidden
              type="file"
              accept="image/jpeg"
              onChange={selectFile}
            />
            <UploadButton
              onClick={() =>
                document.getElementById("fileInput.upload")?.click()
              }
            ></UploadButton>
            {facingMode === "user" && (
              <TimerButton
                disabled={isDisabled()}
                onClick={() => {
                  takePhoto(10);
                }}
              ></TimerButton>
            )}
            {facingMode === "environment" && (
              <TakePhotoButton
                disabled={isDisabled()}
                onClick={async () => {
                  takePhoto(0);
                }}
              />
            )}
          </div>
        </Control>
      </Wrapper>
      <MediaPipe
        shouldRunPose={shouldRunPose}
        setOK={poseOKCallback}
        id={currentId}
        pose={props.pose}
        setLoaded={setPoseLoaded}
        mirrored={mirrored}
      />
    </>
  );
};

export default CameraOverlay;
