// cool animation showing a stylized 3d avatar using animation of svg paths

import React from "react";
interface LoadingAnimationProps {
  color: string;
  size: number;
  speed: number;
}
export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  color,
  size,
  speed,
}) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={color}
        width={`${size}%`}
        height={`${size}%`}
      >
        <g>
          <defs>
            <style>
              {`
                                .rotateY {
                                    animation: rotateYAnimation ${speed}s linear infinite;
                                    transform-origin: center;
                                }
                                @keyframes rotateYAnimation {
                                    0% {
                                        transform: perspective(500px) rotateY(0deg);
                                    }
                                    100% {
                                        transform: perspective(500px) rotateY(360deg);
                                    }
                                }
                            `}
            </style>
          </defs>
          {jaja(color)}
          <g className="rotateY">
            {/* Head */}
            <circle
              cx="12"
              cy="6"
              r="2"
              stroke={color}
              strokeWidth="1"
              fill="none"
            />
            {/* Body */}
            <line
              x1="12"
              y1="8"
              x2="12"
              y2="14"
              stroke={color}
              strokeWidth="1"
            />
            {/* Arms */}
            <line
              x1="8"
              y1="11"
              x2="16"
              y2="11"
              stroke={color}
              strokeWidth="1"
            />
            {/* Legs */}
            <line
              x1="12"
              y1="14"
              x2="9"
              y2="18"
              stroke={color}
              strokeWidth="1"
            />
            <line
              x1="12"
              y1="14"
              x2="15"
              y2="18"
              stroke={color}
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

const jaja = (color:string)=>{
<g className="rotateY">
  {/* Head */}
  <circle cx="12" cy="6" r="2" stroke={color} strokeWidth="1" fill="none" />
  {/* Neck */}
  <line
    x1="12"
    y1="8"
    x2="12"
    y2="9"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Shoulders */}
  <line
    x1="9"
    y1="9"
    x2="15"
    y2="9"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Left arm */}
  <line
    x1="9"
    y1="9"
    x2="6"
    y2="12"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Right arm */}
  <line
    x1="15"
    y1="9"
    x2="18"
    y2="12"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Body */}
  <line
    x1="12"
    y1="9"
    x2="12"
    y2="15"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Hips */}
  <line
    x1="9"
    y1="15"
    x2="15"
    y2="15"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Left leg */}
  <line
    x1="9"
    y1="15"
    x2="8"
    y2="20"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  <line
    x1="8"
    y1="20"
    x2="9"
    y2="23"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  {/* Right leg */}
  <line
    x1="15"
    y1="15"
    x2="16"
    y2="20"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
  <line
    x1="16"
    y1="20"
    x2="15"
    y2="23"
    stroke={color}
    strokeWidth="1"
    fill="none"
  />
</g>;
}
