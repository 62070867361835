import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import LoginButton from "./LoginButton";
import Gdpr from "./Gdpr";
import { useState } from "react";
import LOGO from "../assets/logo.png";
import "../theme/Toolbar.css";
import { home, logOut, logOutOutline } from "ionicons/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect,useContext  } from "react";
import MyContextProvider, { Context } from "../MyContext";
import { context } from "@react-three/fiber";
import { useLocation } from 'react-router-dom';

interface ContainerProps {
  subHeader?: string;
  showGdpr?: boolean;
}

const Toolbar:React.FC<ContainerProps> =  (props: ContainerProps) => {
    const content = useContext(Context);
    useEffect(() => {
    }, [content]);   

  const [showAlert, setShowAlert] = useState(false);
  // check if route contains /photo
    const location = useLocation();
    const isPhoto = location.pathname.includes("/photo") || location.pathname.includes("/cam");
  const [showPopover, setShowPopover] = useState(false);
  const openIt = () => {
    setShowPopover(!showPopover);
  };
  const { isAuthenticated, user, logout } = useAuth0();
  const logOut = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  if (isPhoto) {
    return <></>;
  }

  return (
    <IonHeader>
      <Gdpr isOpen={showAlert}></Gdpr>
      <IonToolbar>
        <IonButtons slot="primary">
          {props.showGdpr && (
            <IonButton onClick={() => setShowAlert(true)}>GDPR</IonButton>
          )}
        </IonButtons>
        <IonImg src={LOGO} className="logo-img"></IonImg>
        <IonButtons slot="start">
          {!props.showGdpr && (
            <IonButton routerLink="/clients">
              <IonIcon src={home}></IonIcon>{" "}
            </IonButton>
          )}

          {props.showGdpr && <LoginButton></LoginButton>}
        </IonButtons>
        {isAuthenticated && (
          <>
            <IonAvatar onClick={openIt} id="user-avatar" slot="end">
              <img
                className="p-2"
                src={
                  user
                    ? user.picture
                    : "https://ionicframework.com/docs/img/demos/avatar.svg"
                }
                alt="Avatar"
              />
            </IonAvatar>
            <IonPopover
              slot="bottom"
              trigger="user-avatar"
              dismissOnSelect={true}
            >
              <IonContent>
                <IonList>
                  <IonItem button={true} detail={false}>
                    {user?.name}
                  </IonItem>
                  <IonItem button={true} detail={false} onClick={logOut}>
                    Log out <IonIcon slot="end" icon={logOutOutline}></IonIcon>
                  </IonItem>
                </IonList>
              </IonContent>
            </IonPopover>
          </>
        )}
      </IonToolbar>

      {props.subHeader && (
        <IonToolbar>
          <IonTitle className="subheader h-1">{props.subHeader}</IonTitle>
        </IonToolbar>
      )}
    </IonHeader>
  );
};
export default Toolbar;


