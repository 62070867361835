// trends component
import React, { useEffect, useState } from "react";
import { DashBoardComponentProps, MeasurementPoint } from "../data/data";
import { MeasureData } from "../../utils/interfaces";
import { MeasurementCard } from "../../../lovable/components/MeasurementCard";
import { getBodyFatTable } from "../data/bodyfatTable";
import { Range } from "../../../lovable/components/BodyFatIndicator";
interface MeasurementHolder {
    title: string;
    expanded: boolean;
    values: MeasurementPoint[];
}
export const Trends: React.FC<DashBoardComponentProps> = ({ clientHolder,clientData }) => {
 const [ranges, setRanges] = useState<Range[]>();
 const [meas, setMeas] = useState<MeasurementHolder[]>([]);
 
  useEffect(() => {
    console.log("trends ",clientData);
  }, [clientData]);
  const showTitle = (title: string) => {
    if (title === "body_fat") {
      return "Body Fat";
    }
    const showName = title
      .split("_")
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))[0];
    return showName;
  };
  function unit(title: string): string {
    switch (title) {
      case "body_fat":
        return "%";
      case "weight":
        return "kg";
      case "waist":
      case "waist_female":
      case "neck":
      case "buttocks":
      case "chest":
      case "leg_circ":
      case "arm_circ":
        return "cm";
      default:
        return "";
    }
  }
  const fetchData = async () => {
    const client = await clientHolder.getClient();
    
    if (!client) {
      return;
    }
    let tables: Range[] = getBodyFatTable(client.gender, client.age).map(
        (c) => {
          return {
            label: c.cat,
            min: c.min,
            max: c.max,
            color: c.color ?? "gray",
          };
        }
      );
    setRanges(tables);    // get first measurement

    const measurements = (await clientHolder.getMeasurements()).filter(
      (m, i) => {
        return i < 100;
      }
    );

    const measurementsKeys = [
      "body_fat",
      "weight",
      client.gender === 'female' ? "waist_female" : "waist",
      "neck",
      "buttocks",
      "chest",
      "leg_circ",
      "arm_circ",
      
    ];
    setMeas(
      measurementsKeys.map((m, i) => {
        return {
          title: m,
          expanded: false,
          values: measurements
            .map((d) => {
              const key = m as keyof MeasureData["measurements"];
              const value = d.measurements[key] || 0;
              return { id: i + "", date: new Date(d.createdAt), value: value };
            })
            .filter((d) => d.value !== 0),
        };
      })
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="md:col-span-2">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {meas
          .filter((m) => m.values.length > 0)
          .map((measurement) => {
            return (
              <MeasurementCard
                key={measurement.title}
                myTarget={clientData?.target?.measurements['body_fat']}
                title={showTitle(measurement.title)}
                values={measurement.values.map((v) => {
                  return { id: v.id, value: Math.round(v.value), date: v.date };
                })}
                ranges={ranges}
                value={Math.round(
                  measurement.values[measurement.values.length - 1].value
                )}
                unit={unit(measurement.title)}
                change={Math.round(
                  measurement.values[measurement.values.length - 1].value -
                    measurement.values[0].value
                )}
              ></MeasurementCard>
            );
          })}
      </div>
    </div>
  );
};
