import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
} from "@ionic/react";
import {
    cameraOutline,
} from "ionicons/icons";
import image from "../assets/Instructions/SidePoseInstructions.png";
import "../theme/Instructions.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { Permissions } from "../components/Permissions";
const SidePosePage: React.FC = () => {
    const { id, gender } = useParams<{ id: string; gender: string }>();
    const [permission, setPermission] = useState(false);
    useEffect(() => {
      }
      , [permission]);
  
    return (
      <IonPage>
        <IonContent>
            <Permissions updated={(granted: any) => setPermission(granted.gyro)}></Permissions>

          {permission && (
            <>
            
              <div className="justify-items-center w-[95%] max-h[80%] mx-[2%] mt-[15%]">
                <img src={image} alt="Image showing person in side pose" />
              </div>
  
              <div className="buttonsUnderInstructions">
                <IonButton
                  size="large"
                  fill="outline"
                  routerLink={`/photo/${id}/${gender}/right`}
                >
                  Open camera <IonIcon icon={cameraOutline} />
                </IonButton>
              </div>
            </>
          )}
        </IonContent>
      </IonPage>
    );
  };

export default SidePosePage;
