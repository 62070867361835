import { useState } from "react";
import { ClientData } from "../../utils/interfaces";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonItem,
  IonList,
  IonPopover,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import InputValidation from "../../clientlist/InputValidation";
import {
  camera,
  cameraOutline,
} from "ionicons/icons";
import { initTask, startTask } from "../../utils/http-service";
import { ClientDataHolder } from "../data/data";
interface NewMeasureTabProps {
  clientHolder: ClientDataHolder;
  client : ClientData;
}

const NewMeasureTab = ({ clientHolder, client }: NewMeasureTabProps) => {
  const [weight, setWeight] = useState<number>();
  const [valid, setValid] = useState(false);
  const [id, setId] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const router = useIonRouter();
  
  const isValidWeight = (input: string) => {
    const number = parseInt(input);
    if (isNaN(number)) return false;
    return number > 30 && number < 200;
  };
  const doInitTask = async () => {
    const task = await initTask(client,clientHolder.auth);

    setId(task.ID);
    setIsOpen(true);
  };
  const doStartTask = async () => {
    if (!id) {
      return;
    }
    await startTask(client, weight, id, clientHolder.auth);
    setIsOpen(false);
    router.push(`/task/${id}/${client.gender}/front_img`);
  };
  const setValue = (
    name: string,
    value: string | number | undefined | null
  ) => {
    if (value === undefined || value === null) {
      return;
    } else if (typeof value === "string") {
      setWeight(parseInt(value));
    } else if (typeof value === "number") {
      setWeight(value);
    }
  };
  return (
      <IonRow>
        <IonFab activated={isOpen} vertical="bottom" horizontal="end">
          <IonFabButton id="bottom-start" onClick={doInitTask}>
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
          <IonFabList side="end"></IonFabList>
        </IonFab>
        <IonPopover
          isOpen={isOpen}
          ref={null}
          focusTrap={false}
          onDidDismiss={() => setIsOpen(false)}
          trigger="bottom-start"
          side="top"
          alignment="start"
        >
          <IonContent>
            <IonList>
              <IonItem detail={false}>
                <InputValidation
                  name="weight"
                  label="Weight"
                  helperText="Enter weight"
                  setIsValid={(name, val) => setValid(val)}
                  errorText="Enter weight"
                  validate={isValidWeight}
                  type="number"
                  setValue={setValue}
                ></InputValidation>
              </IonItem>
              <IonItem button={true} onClick={doStartTask}>
                Start Scan <IonIcon slot="end" icon={cameraOutline}></IonIcon>
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
      </IonRow>
  );
};
export default NewMeasureTab;
