
if (typeof window._env_.REACT_APP_RUN_MODE == 'undefined') {
	window._env_.REACT_APP_RUN_MODE = process.env.REACT_APP_RUN_MODE;
}
let url = "";
if (process.env.API_URL) {
    url = process.env.API_URL;
}else{
    if (window.location.origin.includes("localhost")) {
        url = "http://localhost:7071/api"
    }else if(
      window.location.origin.includes("192")
        
    ){
        url = window.location.origin.replace("3000", "7071")+"/api"

    }else {
        url = "https://sizewall-api.azurewebsites.net/api"
    }
}
export const PIPEDB_URL = url;
//export const PIPEDB_URL = "http://localhost:7071/api"
//export const  PIPEDB_URL = "https://sizewall-function-api.azurewebsites.net/api"

