import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRange,
} from "@ionic/react";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import ThreeDModel from "./3DModel";
import { MeasurementResource, ObjectModel } from "../common/types";
import heightIcon from "../assets/Icons-Images-Video/heightIcon.png";
import headIcon from "../assets/Icons-Images-Video/headIcon.png";
import neckIcon from "../assets/Icons-Images-Video/neckIcon.png";
import chestIcon from "../assets/Icons-Images-Video/chestIcon.png";
import waistIcon from "../assets/Icons-Images-Video/waistIcon.png";
import buttocksIcon from "../assets/Icons-Images-Video/buttocksIcon.png";
import innerLegIcon from "../assets/Icons-Images-Video/innerLegIcon.png";
import outerArmIcon from "../assets/Icons-Images-Video/outerArmIcon.png";
interface MeasurmentResultProps {
  objectModels: ObjectModel[];
  activeModel?: number;
  measurementList: MeasurementResource[];
  visibleMeasurements?: string[];
  //gender?: 'male'|'female';
}
const MeasurmentResult = (props: MeasurmentResultProps) => {
  const visible = props.visibleMeasurements || [
    "height",
    "head",
    "neck",
    "chest",
    "arm_outer",
    "waist",
    "buttocks",
    "leg_inner",
    "arm_circ",
    "leg_circ",
  ];

  const icons = [
    { icon: heightIcon, measurement: "height" },
    { icon: headIcon, measurement: "head" },
    { icon: neckIcon, measurement: "neck" },
    { icon: chestIcon, measurement: "chest" },
    { icon: outerArmIcon, measurement: "arm_outer" },
    { icon: waistIcon, measurement: "waist" },
    { icon: waistIcon, measurement: "waist_female" },
    { icon: buttocksIcon, measurement: "buttocks" },
    { icon: innerLegIcon, measurement: "leg_inner" },
    { icon: innerLegIcon, measurement: "leg_circ" },
    { icon: outerArmIcon, measurement: "arm_circ" },
  ].filter((icon) => visible.includes(icon.measurement));

  //const models = props.objectModels;

  const { objectModels, measurementList } = props;
  const [measurements, setMeasurements] = useState<MeasurementResource>(
    measurementList[0]
  );
  useEffect(() => {
    console.log("active model", props.activeModel);
    if (props.activeModel !== undefined)
        setActiveModel(props.activeModel);
  }, [props.activeModel]);

  const [activeModel, setActiveModel] = useState<number>(0);
  const [showMeasurement, setShowMeasurement] = useState<string>("");

  const buttonsBellow = true;
  function showHideVisualEffect(name: string) {
    setShowMeasurement(name);
  }
  useEffect(() => {
    setMeasurements(measurementList[activeModel]);
  }, [activeModel, measurementList]);

  const buttonGrid = () => {
    const perRow = 2;
    let rows = Math.ceil(icons.length / perRow);
    const colSpan = 12 / perRow + "";
    let grid = [];
    for (let i = 0; i < rows; i++) {
      let row = [];
      for (let j = 0; j < perRow; j++) {
        let index = i * perRow + j;
        if (index < icons.length) {
          const icon = icons[index];
          const name = icon.measurement.split("_")[0];
          const text = `${name.charAt(0).toUpperCase()}${name.slice(
            1
          )} ${getMeasurementByName(icon.measurement)}`;
          const className =
            "text-lg " +
            (showMeasurement === icon.measurement
              ? "selectedMeasurement measurementBtn"
              : "measurementBtn");
          row.push(
            <IonCol
              key={index}
              size={colSpan}
              className={className}
              onClick={() => showHideVisualEffect(icons[index].measurement)}
            >
              <IonItem>{text}</IonItem>
            </IonCol>
          );
        }
      }

      grid.push(<IonRow key={i}>{row}</IonRow>);
    }
    return <IonGrid className="overlay">{grid}</IonGrid>;
  };
  const timeAgoString = (date: Date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffInSeconds = diff / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30;
    const diffInYears = diffInMonths / 12;
    if (diffInYears >= 1) {
      return Math.round(diffInYears) + " years ago";
    } else if (diffInMonths >= 1) {
      return Math.round(diffInMonths) + " months ago";
    } else if (diffInDays >= 1) {
      return Math.round(diffInDays) + " days ago";
    } else {
      return "Today";
    }
  };
  const getMeasurementByName = (name: string, meas?: MeasurementResource) => {
    let res = 0;
    if (!meas) {
      meas = measurements;
    }

    res = getValue(meas);
    return res > 0 ? res + " cm" : "x";

    function getValue(meas: MeasurementResource) {
      switch (name) {
        case "height":
          return Math.round(meas.height / 10);
        case "head":
          return Math.round(meas.head / 10);
        case "neck":
          return Math.round(meas.neck / 10);
        case "chest":
          return Math.round(meas.chest / 10);
        case "arm_outer":
          return Math.round(meas.arm_outer / 10);
        case "waist":
          return Math.round(meas.waist / 10);
        case "buttocks":
          return Math.round(meas.buttocks / 10);
        case "leg_inner":
          return Math.round(meas.leg_inner / 10);
        case "leg_circ":
          return Math.round((meas.leg_circ || 0) / 10);
        case "arm_circ":
          return Math.round((meas.arm_circ || 0) / 10);
        case "waist_female":
          return Math.round((meas.waist_female || 0) / 10);

        default:
          return 0;
      }
    }
  };

  return (
    <IonRow>
      {objectModels.length > 1 && props.activeModel === undefined ? (
        <IonCol size="12" className="px-8 ">
          <IonLabel className="py-2 text-center text-md">
            {timeAgoString(new Date(objectModels[activeModel].createdAt))}
          </IonLabel>
          <></>
          <IonRange
            aria-label="Range with pin"
            snaps={true}
            value={activeModel}
            min={0}
            //debounce={200}
            max={objectModels.length - 1}
            onIonInput={({ detail }) => setActiveModel(detail.value as number)}
            onIonChange={({ detail }) => {
              setActiveModel(detail.value as number);
            }}
          ></IonRange>
        </IonCol>
      ) : (
        <></>
      )}
      <IonCol size="12">
        <div className="resultPhoto m-0">
          <Canvas
            camera={{
              fov: 40,
              aspect: window.innerWidth / window.innerHeight,
              zoom: 1.3,
              near: 1,
              far: 1100,
            }}
          >
            <color attach="background" args={["white"]} />
            <Suspense fallback={null}>
              <ThreeDModel
                models={objectModels}
                activeModel={activeModel}
                showMeasurement={showMeasurement}
              />
            </Suspense>
          </Canvas>
        </div>
      </IonCol>
      {buttonsBellow && buttonGrid()}
      {!buttonsBellow && (
        <div className="overlay">
          <IonCol>
            <IonList className="measureList overflow-y-scroll">
              {icons.map((icon, index) => {
                const name = icon.measurement.split("_")[0];
                const text = `${name.charAt(0).toUpperCase()}${name.slice(
                  1
                )} ${getMeasurementByName(icon.measurement)}`;
                return (
                  <IonItem
                    key={index}
                    color="0xff38a9"
                    className={
                      showMeasurement === icon.measurement
                        ? "selectedMeasurement measurementBtn"
                        : "measurementBtn left-0"
                    }
                    onClick={() => {
                      showHideVisualEffect(icon.measurement);
                    }}
                  >
                    <div className="text-nowrap text-xs p-0">{text}</div>
                  </IonItem>
                );
              })}
            </IonList>
          </IonCol>
        </div>
      )}
    </IonRow>
  );
};

export default MeasurmentResult;
