import React from 'react';


const SvgHeadWithArrowRight = (color = "red", length = 50, height = 150): string => {
    const strokeWidth = 10;
    return `<svg width="${length + 100}" height="${height}" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="100" r="40" stroke="${color}" stroke-width="${strokeWidth}" fill="none" />
        <circle cx="65" cy="90" r="5" fill="${color}" /> <!-- Added right eye -->
        <rect x="45" y="140" width="10" height="20" fill="${color}" />
        <rect x="30" y="160" width="40" height="30" fill="${color}" />
        <line x1="100" y1="100" x2="150" y2="100" stroke="${color}" stroke-width="${strokeWidth}" />
        <line x1="140" y1="90" x2="150" y2="100" stroke="${color}" stroke-width="${strokeWidth}" />
        <line x1="140" y1="110" x2="150" y2="100" stroke="${color}" stroke-width="${strokeWidth}" />
    </svg>`;
};

export default SvgHeadWithArrowRight;
