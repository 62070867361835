import { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonChip,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  useIonRouter,
} from "@ionic/react";
import Toolbar from "../../components/Toolbar";
import { ClientData } from "../utils/interfaces";
import { addClient, AuthProvider } from "../utils/http-service";
import InputValidation from "./InputValidation";
import { useAuth0 } from "@auth0/auth0-react";
interface ValidationErrors {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  age?: boolean;
  height?: boolean;
  gender?: boolean;
}
const ErrorNotice = ({ error }: { error: string }) => {
  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
      {error}
    </div>
  );
};
export const AddClient = () => {
  const [client, setClient] = useState<ClientData>({
    firstName: "",
    profileImage: "",
    ID: "",
    gender: "female",
    lastName: "",
    trainer: "",
    createdAt: new Date(),
    age: 0,
    height: 150,
    email: "",
  });
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState<string>();
  const [valid, setValid] = useState<ValidationErrors>({
    firstName: false,
    lastName: false,
    email: false,
    age: false,
    height: false,
    gender: false,
  });
  const router = useIonRouter();
  const [disabled, setDisabled] = useState(false);
  const auth = new AuthProvider(useAuth0().getAccessTokenSilently);
  useEffect(() => {
    setDisabled(Object.values(valid).some((v) => v === false));
  }, [valid]);

  const saveClient = async () => {
    const anyError = Object.values(valid).some((v) => v === false);
    setError(undefined);
    if (anyError) {
      setError("Please fill in all fields");
      return;
    }
    setSaved(false);

    addClient(client, auth)
      .then((res) => {
        client.ID = res.ID;
        setSaved(true);
        setTimeout(() => {
          router.push(`/dashboard/${client.ID}`);
        }, 1500);
      })
      .catch((err) => {
        if (err.message.includes("duplicate")) {
          setError("Client already exists");
        } else {
          setError(err.message);
        }
      });
  };
  const setIsValid = (name: string, value: boolean) => {
    setValid({ ...valid, [name]: value });
  };
  const ValidString = (len: number) => {
    return (value: string): boolean => {
      return value.length > len;
    };
  };

  const validNum = (min: number, max: number) => {
    return (value: string) => {
      const numVal = parseInt(value);
      if (isNaN(numVal)) {
        return false;
      }

      return numVal >= min && numVal <= max;
    };
  };

  const validateEmail = (
    value: string | number | undefined | null
  ): boolean => {
    if (!value) {
      return false;
    }
    const jaja = value
      .toString()
      .match(
        /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      );
    return jaja !== null;
  };

  const setValue = (
    name: string,
    value: string | number | undefined | null
  ) => {
    setClient({ ...client, [name]: value });
  };
  return (
    <IonPage>
      <Toolbar />
      <IonContent>
        <IonCard>
          <IonCardHeader>
            <h2 className="text-lg">New Client</h2>
          </IonCardHeader>
          <IonList>
            <IonItem>
              <InputValidation
                name="firstName"
                validate={ValidString(1)}
                label="First Name"
                errorText="Enter valid name"
                setIsValid={setIsValid}
                setValue={setValue}
                helperText="Enter first name"
              />
            </IonItem>
            <IonItem>
              <InputValidation
                name="lastName"
                validate={ValidString(1)}
                label="Last Name"
                errorText="Enter valid name"
                setIsValid={setIsValid}
                setValue={setValue}
                helperText="Enter last name"
              />
            </IonItem>
            <IonItem>
              <InputValidation
                name="age"
                validate={validNum(18, 100)}
                type={"number"}
                label="Age"
                errorText="Enter valid age"
                setIsValid={setIsValid}
                setValue={setValue}
                helperText="Enter age"
              />
            </IonItem>
            <IonItem>
              <InputValidation
                name="height"
                validate={validNum(100, 250)}
                type={"number"}
                label="Height (cm)"
                errorText="Enter valid height"
                setIsValid={setIsValid}
                setValue={setValue}
                helperText="Enter height"
              />
            </IonItem>
            <IonItem>
              <InputValidation
                name="email"
                validate={validateEmail}
                label="Email"
                errorText="Enter valid email"
                setIsValid={setIsValid}
                setValue={setValue}
                helperText="Enter email"
              />
            </IonItem>
            <IonItem>
              <IonRadioGroup
                allowEmptySelection={false}
                onIonChange={(e) => {
                  const target = e.target as HTMLIonRadioGroupElement;
                  setClient({
                    ...client,
                    gender: target.value as "male" | "female",
                  });
                  setValid({ ...valid, gender: true });
                }}
              >
                <IonLabel>Gender</IonLabel>

                <IonRadio value="male">Male</IonRadio>
                <IonRadio value="female">Female</IonRadio>
              </IonRadioGroup>
            </IonItem>
          </IonList>

          <div className="flex p-4">
            <br />
            <IonButton
              fill="outline"
              color="warning"
              className=""
              routerLink={`/clients?load=${client.ID}`}
            >
              Back
            </IonButton>
            <IonButton
              fill="outline"
              color="success"
              className=""
              disabled={disabled}
              onClick={() => saveClient()}
            >
              Save
            </IonButton>

            {error || saved ? (
              <>
                {error && <IonChip color="danger">{error}</IonChip>}

                {saved && <IonChip color="success">Client saved</IonChip>}
              </>
            ) : null}
          </div>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
