import { PIPEDB_URL } from "../../common/pipedb-url";
import { ClientData, LogEntry, MeasureData, ObjectModelData, ShareLinkData, StarParams, TaskData } from "./interfaces";
import { mockClients, mockMeasurements, mockModels } from "./mock";
const IS_LOG = false;
const IS_ERROR_LOG = true;
if (IS_LOG) {
    console.warn('Logging enabled');
    const original = console.log;
    console.log = function (...data: any) {
        console.warn('Logging', JSON.stringify(arguments));
        postLog({
            level: 'info',
            text: JSON.stringify(arguments),
            timestamp: new Date()
        }, new AuthProvider(async () => ""));
        original.apply(console, data);
    }
}
if (IS_ERROR_LOG) {
    const originalErrorLog = console.error;
    const originalError = window.onerror;

    console.error = function (...data: any) {
        postLog({
            level: 'error',
            text: JSON.stringify(arguments),
            timestamp: new Date()
        }, new AuthProvider(async () => ""));
        originalErrorLog.apply(console, data);
    }

    window.onerror = function (message, source, lineno, colno, error) {

        const messageStr = message instanceof Event ? error?.message || 'no message' : message;
        postLog({
            level: 'error',
            text: JSON.stringify(arguments),
            error: error,
            timestamp: new Date(),
            stackTrace: error?.stack
        }, new AuthProvider(async () => ""));
        if (originalError) {
            originalError(message, source, lineno, colno, error);
        }
    }
}

export const IS_MOCK = false;
export class AuthProvider {
    getAccessTokenSilently: () => Promise<string>;
    code?: string;

    constructor(getAccessTokenSilently: () => Promise<string>, code?: string) {
        this.code = code;
        this.getAccessTokenSilently = getAccessTokenSilently;
    }
    getAuthHeader = async () => {
        let headers = {
            'Content-Type': 'application/json',
        } as { [key: string]: string };
        if (this.code) {
            headers = {
                ...headers,
                Authorization: `Code ${this.code}`
            };
        } else {
            const token = await this.getAccessTokenSilently();
            if (token) {
                headers = {
                    ...headers,
                    Authorization: `Bearer ${token}`
                };
            }
        }
        return headers;
    }
}

export const post = async (url: string, data: any, auth: AuthProvider) => {

    return fetch(`${PIPEDB_URL}/${url}`, {
        method: 'POST',
        headers: await auth.getAuthHeader(),
        body: JSON.stringify(data),
    });
}
export const get = async (url: string, auth: AuthProvider) => {
    const headers = await auth.getAuthHeader();
    try {
        return await fetch(`${PIPEDB_URL}/${url}`, { headers: headers });
    }catch(e){
        // TODO improve UI error handling
        alert("Error fetching data try reloading page");
        return {json: async () => [], status: 500, text: async () => "Error fetching data"};
    }
}

export const del = async (url: string) => {
    return fetch(`${PIPEDB_URL}/${url}`, {
        method: 'DELETE',
    });
}
export const getClients = async (auth: AuthProvider): Promise<ClientData[]> => {
    if (IS_MOCK) {
        return mockClients();
    }
    const res = await get('client', auth);
    return res.json();
}
export const addResource = async (resourceName: string, taskID: string, data: any, auth: AuthProvider, raw = false) => {
    const res = await post(`task/${taskID}/${resourceName}`, data, auth);

    if (raw) {
        return res;
    }
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const getResource = async (resourceName: string, taskID: string, auth: AuthProvider, raw = false) => {
    const res = await get(`task/${taskID}/${resourceName}`, auth);
    if (raw) {
        return res;
    }
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}

export const addClient = async (client: ClientData, auth: AuthProvider) => {
    const res = await post('client', client, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const getClient = async (id: string, auth: AuthProvider): Promise<ClientData> => {
    if (IS_MOCK) {
        return (await mockClients()).find(c => c.ID === id) as ClientData;
    }
    const res = await get(`client/${id}`, auth);
    return res.json();
}
export const initTask = async (client: ClientData, auth: AuthProvider): Promise<TaskData> => {
    const task = (await (await post('task', { client: client.ID }, auth)).json()) as TaskData;
    return task;
}

export const startTask = async (client: ClientData, weight: number | undefined, id: string, auth: AuthProvider) => {
    await post(`task/${id}/front_proj`, {
        scale: [2.0, 1.5],
        center: [0, 0]
    }, auth);
    await post(`task/${id}/right_proj`, {
        scale: [2.0, 1.5],
        center: [0, 0]
    }, auth);
    await post(`task/${id}/height_input`, {
        value: Number(client.height / 100)
    }, auth);
    await post(`task/${id}/gender`, {
        value: client.gender
    }, auth);
    if (weight) {
        await post(`task/${id}/weight`, {
            value: weight
        }, auth);
    }
    return id;
}
export const getLink = async (id: string, auth: AuthProvider): Promise<ShareLinkData> => {
    const res = await get(`link/${id}`, auth);
    return res.json();
}
export const saveLink = async (id: string, link: ShareLinkData, auth: AuthProvider) => {
    const res = await post(`link/${id}`, { link }, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const saveTask = async (id: string, auth: AuthProvider): Promise<TaskData> => {
    const res = await post(`save_task/${id}`, {}, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const getMeasurements = async (clientId: string, auth: AuthProvider): Promise<MeasureData[]> => {
    if (IS_MOCK) {
        return mockMeasurements(clientId);
    }
    const res = await get(`measurements/${clientId}/`, auth);
    return res.json();
}

export const getModels = async (clientId: string, auth: AuthProvider): Promise<ObjectModelData[]> => {
    if (IS_MOCK) {
        return mockModels(clientId);
    }
    const res = await get(`models/${clientId}/`, auth);
    return res.json();
}
export const getModel = async (clientId: string, taskId: string, auth: AuthProvider): Promise<String> => {
    if (IS_MOCK) {
        return (await mockModels(clientId))[0].obj;
    }
    const res = await get(`models/${clientId}/${taskId}.obj`, auth);
    return res.text();
}
export const getStarParams = async (clientId: string, taskId: string, auth: AuthProvider): Promise<StarParams> => {
    const res = await get(`models/${clientId}/${taskId}_params.json`, auth);
    return res.json();
}
export const generateModel = async (taskId: string,gender:'male'|'female',percentage:number,starParams:number[], auth: AuthProvider) => {
    const res = await get(`generate/${taskId}/${gender}/${percentage}`, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const setTarget = async (clientId: string,taskID:string, target: MeasureData['measurements'], auth: AuthProvider) => {
    const res = await post(`client/${clientId}/${taskID}/target`, target, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res
}



export const postLog = async (data: LogEntry, auth: AuthProvider) => {
    const res = await post('log', data, auth);
    if (res.status !== 200) {
        console.error(await res.text());
    }
    return
}

