import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Card } from "./ui/card";

interface DataPoint {
  date: string;
  value: number;
}

interface ProgressChartProps {
  data: DataPoint[];
  title: string;
  unit: string;
  myTarget?: number;
}

export const ProgressChart = ({ data, title, unit, myTarget }: ProgressChartProps) => {
    if (myTarget && title.toLowerCase().includes("body")) {
        console.log("myTarget", myTarget);
        data = data.map((point) => ({
            date: point.date,
            value: point.value,
            target: myTarget,
        }));
    }
  return (
    <Card className="p-6 backdrop-blur-lg bg-card/80 border border-gray-200/20 h-[300px] animate-fade-in">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis
            dataKey="date"
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => `${value}${unit}`}
          />
          <Tooltip
            content={({ active, payload }) => {
                console.log("payload", payload);
              if (active && payload && payload.length) {
                return (
                  <div className="rounded-lg border bg-background p-2 shadow-sm">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex flex-col">
                        <span className="text-[0.70rem] uppercase text-muted-foreground">
                          Value
                        </span>
                        <span className="font-bold text-muted-foreground">
                          {payload[0].value}
                          {unit}
                        </span>
                      </div>
                   { payload[0].payload.target &&  <div className="flex flex-col">
                        <span className="text-[0.70rem] uppercase text-muted-foreground">
                          Target
                        </span>
                        <span className="font-bold text-muted-foreground">
                          {payload[0].payload.target}
                          {unit}
                        </span>
                      </div>} 
                      <div className="flex flex-col">
                        <span className="text-[0.70rem] uppercase text-muted-foreground">
                          Date
                        </span>
                        <span className="font-bold text-muted-foreground">
                          {payload[0].payload.date}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8CA891"
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 4, strokeWidth: 0 }}
          />
         <Line type="monotone" dataKey="target" stroke="#82ca9d"   strokeWidth={2}
            dot={false} />

        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};