// Mediapipe component for pose estimation
//
// This component is a wrapper around the mediapipe pose estimation
// model. It takes a photo as input and returns the pose estimation
// results.

import {
  FilesetResolver,
  PoseLandmarker,
  DrawingUtils,
  PoseLandmarkerResult,
  NormalizedLandmark,
  Landmark,
} from "@mediapipe/tasks-vision";
import { useEffect, useState } from "react";
import SvgHeadWithArrowRight from "./SvgHead";
import {
  connections,
  getFacing,
  isErrorLine,
  isOkLandmark,
  keys,
  targetAnglesFrontPose,
  targetAnglesSidePose,
  isInside,
  visibleLandmarksSide,
  visibleLandmarksFront,
} from "./PoseChecker";

interface MediaPipeProps {
  setOK: (ok: boolean) => void;
  setLoaded: (loaded: boolean) => void;
  id: string;
  mirrored?: boolean;
  shouldRunPose: (id:string, log?:boolean) => boolean;
  pose: "front" | "right";
}
const svgArrowRight = (color: "red" | "green", length = 50, height = 150) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${color}" width="${length}px" height="${height}px" style="transform: rotate(90deg);"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2L3 9h3v11h12V9h3z"/></svg>`;
};

//let RUN: boolean = false;
let MIRROR: boolean = false;
//
export const MediaPipe: React.FC<MediaPipeProps> = ({
  setOK,
  //run,
  mirrored,
  pose,
  id,
  shouldRunPose,
  setLoaded,
}) => {
  const [webcamRunning, setWebcamRunning] = useState(true);
 
   useEffect(() => {
   if (shouldRunPose(id)) {
        loadPose(id);
    }
  }, [id]); 
  useEffect(() => {
    if (mirrored !== MIRROR) {
    }
    MIRROR = mirrored === true;
  }, [mirrored]);

  const shouldRun = (id:string) => {
    return shouldRunPose(id);
  };
  async function loadPose(id:string) {
  /*   if (run === false) {
      return;
    } */
    console.log("MediaPipe STARTING!!!");

    const runningMode = "VIDEO";
    const vision = await FilesetResolver.forVisionTasks(
      "/shared/wasm"
      //"https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
    );
    const poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `/shared/models/pose_landmarker_lite.task`,
        delegate: "GPU",
      },

      runningMode: runningMode,
      minPosePresenceConfidence: 0.5,
      numPoses: 1,
      minTrackingConfidence: 0.1,
    });

    let lastVideoTime = -1;
    let i = 0;
    let framesOK = 0;

    function renderLoop(
      id: string,
      poseInWorld?: Landmark[][],
      poseInDraw?: Landmark[][]
    ): void {
      let poseLocalWorld: Landmark[][] = poseInWorld || [];
      let poseLocalDraw: Landmark[][] = poseInDraw || [];
      if (!shouldRunPose(id)) {
        console.log("MediaPipe STOPPED");
        return;
      }
      const video = document.querySelector("video") as HTMLVideoElement;

      if (!video || !video.videoWidth || !video.videoHeight) {
        setTimeout(() => {
          console.log(shouldRunPose(id, true));
          console.log("settimeout");
          renderLoop(id);
        }, 1000);
        return;
      }
      const canvasEl = document.getElementById("canvas") as HTMLCanvasElement;
      if (!canvasEl) {
        console.log("No canvas element");
        requestAnimationFrame(() => renderLoop(id));
        throw new Error("No canvas element");
      }

      const canvasCtx = canvasEl.getContext("2d") as CanvasRenderingContext2D;
      if (!canvasCtx) {
        console.log("No canvas context");
        requestAnimationFrame(() => renderLoop(id));
        throw new Error("No canvas context");
      }
      if (!shouldRun(id)) {
        console.log("MediaPipe STOPPED");
        canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height);
        return;
      }
      canvasEl.width = video.videoWidth;
      canvasEl.height = video.videoHeight;

      const videoBox = video.getBoundingClientRect();
      canvasEl.style.marginLeft = videoBox.left + "px";
      canvasEl.style.marginTop = videoBox.top + "px";

      canvasEl.style.position = "absolute";
      canvasEl.style.width = videoBox.width + "px";
      canvasEl.style.height = videoBox.height + "px";
      const drawingUtils = new DrawingUtils(canvasCtx);

      const toShow = Object.values(keys);
      const startTimeMs = performance.now(); // video.currentTime * 1000;
      if (video.currentTime !== lastVideoTime) {
        poseLandmarker.detectForVideo(video, startTimeMs, (result) => {
          if (i % 250 === 0 && result.landmarks.length > 0) {
            console.log(i);
            console.log(
              "MediaPipe result " + id ,
              result.landmarks[0][0],
              //result.worldLandmarks[0][0]
            );
          }
          setLoaded(true);
          const marginH = 0.05;
          const marginW = pose === "front" ? 0.05 : 0.25;
          // center box corners based on the margins in normalized coordinates
          const centerBox: NormalizedLandmark[] = [
            { x: marginW, y: marginH, z: -1, visibility: 1 },
            { x: 1 - marginW, y: marginH, z: -0.2, visibility: 1 },
            { x: 1 - marginW, y: 1 - marginH, z: -0, visibility: 1 },
            { x: marginW, y: 1 - marginH, z: -0, visibility: 1 },
          ];
          const centerConnectors = [
            { start: 0, end: 1 },
            { start: 1, end: 2 },
            { start: 2, end: 3 },
            { start: 3, end: 0 },
          ];

          lastVideoTime = video.currentTime;

          canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height);

          let frameOK = true;
          if (result.landmarks.length > 0) {
            i++;
            const drawLandmarks = result.landmarks[0]; // Used for drawing the landmarks.
            const worldLandmarks = result.worldLandmarks[0]; // used for calculating the angles
            let poseFinalWorld: Landmark[] = [];
            let poseFinalDraw: Landmark[] = [];
            const videoSize = {
              width: video.videoWidth,
              height: video.videoHeight,
            };
            let average = calculateAveragePose(
              drawLandmarks,
              toShow,
              poseLocalDraw
            );
            poseFinalDraw = average.result;
            poseLocalDraw = average.updatedList;
            // TODO rename averageWorld to averageCalc
            // Normalize the landmarks to the video size
            let averageWorld = calculateAveragePose(
              drawLandmarks,
              toShow,
              poseLocalWorld,
              videoSize
            );
            //averageWorld = calculateAveragePose(worldLandmarks, toShow, poseLocalWorld);
            poseFinalWorld = averageWorld.result;
            poseLocalWorld = averageWorld.updatedList;
            // average the last 10 poses
            const isInsideBox = isInside(poseFinalDraw, marginW, marginH);
            frameOK = frameOK && isInsideBox;
            drawingUtils.drawConnectors(centerBox, centerConnectors, {
              lineWidth: 20,
              color: () => (isInsideBox ? "green" : "red"),
            });

            if (pose === "right") {
              let face = getFacing(poseFinalWorld);
              if (face) {
                if (!MIRROR) {
                  // flip the face if not mirrored
                  if (face === "right") {
                    face = "left";
                  } else if (face === "left") {
                    face = "right";
                  }
                }

                const img = new Image();
                
                img.src = `data:image/svg+xml;base64,${btoa(
                  SvgHeadWithArrowRight(
                    face === "right" ? "green" : "red",
                    100,
                    200
                  )
                )}`;
                //img.src = `data:image/svg+xml;base64,${btoa(svgHand(face === "right" ? "green" : "red", 100, 0))}`;
                if (face !== "right") {
                  frameOK = false;
                }
                const arrW = 200;
                const arrH = 200;
                canvasCtx.drawImage(
                  img,
                  (poseFinalDraw[0].x + 0.1) * canvasEl.width,
                  poseFinalDraw[0].y * canvasEl.height - arrH / 2,
                  arrW,
                  arrH
                );
              }
            }
            const visible =
              pose === "right" ? visibleLandmarksSide : visibleLandmarksFront;
            const visibleByIndex = Object.values(keys).map((key) =>
              visible.includes(key)
            );
            const connectionsToDraw = connections.filter(
              (conn) => visibleByIndex[conn.start] && visibleByIndex[conn.end]
            );
            const allConnectionsOK = !connectionsToDraw.some((conn, i) =>
              isErrorLine(
                poseFinalWorld,
                { index: i },
                pose === "right" ? targetAnglesSidePose : targetAnglesFrontPose,
                pose,
                MIRROR
              )
            );
            frameOK = frameOK && allConnectionsOK;
            if (frameOK) {
              framesOK++;
            } else {
              framesOK = 0;
              setOK(false);
            }
            if (framesOK > 10) {
              setOK(true);
            }

            drawingUtils.drawLandmarks(
              poseFinalDraw.filter((lm, i) => visibleByIndex[i]),
              {
                radius: () => 15,
                color: (data) =>
                  isOkLandmark(
                    poseFinalDraw as Landmark[],
                    data.index,
                    marginW,
                    marginH,
                    true
                  )
                    ? "green"
                    : "red",
              }
            );
            drawingUtils.drawConnectors(poseFinalDraw, connectionsToDraw, {
              lineWidth: 25,
              color: (data) => {
                if (data.index === undefined) {
                  return "gray";
                }
                return isErrorLine(
                  poseFinalWorld,
                  data,
                  pose === "right"
                    ? targetAnglesSidePose
                    : targetAnglesFrontPose,
                  pose,
                  MIRROR
                )
                  ? "red"
                  : "green";
              },
            });
          }
        });
      }

      if (webcamRunning === true) {
        window.requestAnimationFrame(() =>
          renderLoop(id, poseLocalWorld, poseLocalDraw)
        );
      }
    }
    requestAnimationFrame(() => {
      if (shouldRun(id)) {
        renderLoop(id);
      }
    });
  }
  return (
    <canvas
      id="canvas"
      style={{ position: "absolute", zIndex: 10, pointerEvents: "none" }}
    ></canvas>
  );
};
function calculateAveragePose(
  worldLandmarks: Landmark[],
  toShow: number[],
  poseLocalWorld: Landmark[][],
  videoSize?: { width: number; height: number }
) {
  const worldLandmarksToAdd = worldLandmarks
    .filter((data, i) => toShow.includes(i))
    .map((data) => {
      if (!MIRROR) {
        return data;
      } else {
        return {
          x: 1 - data.x,
          y: data.y,
          z: data.z,
          visibility: data.visibility,
        };
      }
    });

  if (!poseLocalWorld) {
    poseLocalWorld = [];
  } else if (poseLocalWorld.length > 15) {
    poseLocalWorld.shift();
  }
  poseLocalWorld.push(worldLandmarksToAdd);

  const poseFinalWorld = poseLocalWorld.reduce((acc, val) => {
    val.forEach((data, i) => {
      if (!acc[i]) {
        acc[i] = { x: 0, y: 0, z: 0, visibility: 0 };
      }
      acc[i].x += data.x;
      acc[i].y += data.y;
      acc[i].z += data.z;
      acc[i].visibility += data.visibility;
    });
    return acc;
  }, []);
  const len = poseLocalWorld.length;
  poseFinalWorld.forEach((data) => {
    data.x /= len;
    data.y /= len;
    data.z /= len;
    data.visibility /= len;
  });
  if (videoSize) {
    poseFinalWorld.forEach((data) => {
      data.x *= videoSize.width;
      data.y *= videoSize.height;
    });
  }
  return { result: poseFinalWorld, updatedList: poseLocalWorld };
}
