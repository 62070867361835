import { ClientData, MeasureData, ObjectModelData } from "./interfaces";

const clients: ClientData[] = [
    {
        ID: '1',
        firstName: 'Anna',
        lastName: 'Svensson',
        email: 'anna@sizewall.com',
        age: 45,
        height: 165,
        trainer: '1',
        gender: 'female',
        profileImage: 'https://randomuser.me/api/portraits/women/52.jpg',
        createdAt: new Date()
    },
    {
        ID: '2',
        firstName: 'Mårten',
        lastName: 'Johansson',
        email: 'mårten@sizewall.com',
        age: 35,
        height: 175,
        gender: 'male',
        trainer: '1',
        profileImage: 'https://randomuser.me/api/portraits/men/66.jpg',
        createdAt: new Date(),
    }];




const samples = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];


export const mockModels = async (clientId: string): Promise<ObjectModelData[]> => {
    // fetch data from server



    const objectModels = await Promise.all(samples.map(async (i) => {
        const res = await fetch(`samples/${clientId}/shape_${i}.json`);
        const data = (await res.json() as ObjectModelData);
        data.taskID = `${clientId}_${i}`;
        return data;
    }));
    return objectModels;
}
// estimate of body weight from waist circumference and height in cm 
/**
 * Estimate weight in kilograms based on height and waist circumference.
 * @param {number} height - Height in meters.
 * @param {number} waistCircumference - Waist circumference in centimeters.
 * @returns {number} - Estimated weight in kilograms.
 */
function estimateWeight(height:number, waistCircumference:number): number {
    if (height <= 0 || waistCircumference <= 0) {
        throw new Error("Height and waist circumference must be positive numbers.");
    }
    height = height / 100; // Convert height to meters
    //waistCircumference = waistCircumference / 100; // Convert waist circumference to meters
    // Average BMI based on waist-to-height ratio
    const waistToHeightRatio = waistCircumference / (height * 100);

    // Adjust BMI based on waist-to-height ratio
    const averageBMI = 22 + (waistToHeightRatio - 0.5) * 22;

    // Calculate weight using BMI
    const weight = averageBMI * height * height;
    return weight; // Return weight rounded to 2 decimal places
}
export const mockMeasurements = async (clientId: string): Promise<MeasureData[]> => {
    const measurements = await Promise.all(samples.map(async (i) => {
        const res = await (await fetch(`samples/${clientId}/measurements_${i}.json`)).json();
        const data = { ...res, measurements:res};
        const height = await mockClients().then((clients) => clients.find((c) => c.ID === clientId)?.height) ||160;
        data.measurements.weight = Math.round(estimateWeight(height, (data.measurements.waist)/10));
        data.taskID = `${clientId}_${i}`;
        return data;
    }));
    return measurements;
}




export const mockClients = async (): Promise<ClientData[]> => {
    return clients;
}
