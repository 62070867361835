// Show lean mass, body fat mass, and body fat percentage in a graph

import { useEffect, useRef, useState } from "react";
import { ClientData } from "../../utils/interfaces";
import { Loading } from "./Loading";
import { DashBoardComponentProps } from "../data/data";
import { MeasurementCard } from "../../../lovable/components/MeasurementCard";
import { Range } from "../../../lovable/components/BodyFatIndicator";
import { getBodyFatTable } from "../data/bodyfatTable";
import { ObjectModel } from "../../../common/types";
import MeasurmentResult from "../../../components/MeasurementsResults";

export const OverallFitness: React.FC<DashBoardComponentProps> = ({
  clientHolder,
}) => {
  const delayTimeout = useRef<NodeJS.Timeout | null>(null);
  const [clientData, setClientData] = useState<ClientData>();
  const [ranges, setRanges] = useState<Range[]>();
  const [activeModel, setActiveModel] = useState<number>(0);

  const [bodyFat, setBodyFat] = useState<number[]>([]);

  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState<ObjectModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const client = await clientHolder.getClient();
      setClientData(client);
      let table: Range[] = getBodyFatTable(client.gender, client.age).map(
        (c) => {
          return {
            label: c.cat,
            min: c.min,
            max: c.max,
            color: c.color ?? "gray",
          };
        }
      );
      setRanges(table);
      const data = await clientHolder.getMeasurements("cm");
      const bodyFatC = data
        .filter((m) => m.measurements.body_fat !== undefined)
        .map((m) => {
          return m.measurements.body_fat;
        }) as number[];
      setBodyFat(bodyFatC);
    };
    fetchData();
  }, []);
  const setBFTarget = async (percentage: number) => {
    const updated = await clientHolder.setTarget(percentage);
    setClientData(updated);
  };
  const onTargetChange = async (percentage: number, commit: boolean) => {
    // check if estimation already exists
    const existingIndex = models.findIndex(
      (m) => m.id === percentage.toString()
    );
    if (existingIndex !== -1) {
      setActiveModel(existingIndex);
      return Promise.resolve();
    }
    if (commit && !loading) {
      await fetchEstimation(percentage);
      return Promise.resolve();
    }
    if (delayTimeout.current) {
      clearTimeout(delayTimeout.current);
    }

    delayTimeout.current = setTimeout(() => {
      onTargetChange(percentage, true);
    }, 500);
    return Promise.resolve();
  };
  const fetchEstimation = async (percentage: number) => {
    setLoading(true);
    const estimation = (await clientHolder.getEstimation(
      percentage / 100.0
    )) as string;

    const newModel = {
      obj: estimation,
      id: percentage.toString(),
      createdAt: new Date().toISOString(),
      visibleEffects: [],
      showMeasurement: "",
      measurements: {
        height: 0,
        head: 0,
        neck: 0,
        chest: 0,
        waist: 0,
        buttocks: 0,
        leg_inner: 0,
        arm_outer: 0,
        leg_circ: 0,
      },
    } as ObjectModel;

    const newModels = [...models, newModel];
    const newIndex = newModels.length - 1;
    setModels(newModels);
    setActiveModel(newIndex);
    setLoading(false);

    return Promise.resolve();
  };
  const currentBodyFat = () => {
    return Math.round(bodyFat[bodyFat.length - 1]);
  };

  if (bodyFat.length === 0) {
    return <div>Loading...</div>;
  }

  return clientData ? (
    <>
      <MeasurementCard
        unit="%"
        title="Body Fat"
        value={currentBodyFat()}
        ranges={ranges}
        isTarget={true}
        myTarget={clientData.target?.measurements.body_fat}
        onTargetChange={onTargetChange}
        setTarget={setBFTarget}
      ></MeasurementCard>
      {models.length > 0 && (
        <MeasurmentResult
          objectModels={models}
          visibleMeasurements={[]}
          activeModel={activeModel}
          measurementList={[]}
        ></MeasurmentResult>
      )}
    </>
  ) : (
    <Loading></Loading>
  );
};
