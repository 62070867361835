import { cn } from "../lib/utils";
import { useState, useRef, useEffect } from "react";
import { Slider } from "./ui/slider";
import { IonButton } from "@ionic/react";

interface BodyFatIndicatorProps {
  percentage: number;
  className?: string;
  ranges: Range[];
  expanded?: boolean;
  isTarget?: boolean;
  myTarget?: number;
  setTarget?: (value: number) => void;
  onTargetChange?: (value: number, commit:boolean) => void;
}

export interface Range {
  label: string;
  min: number;
  max: number;
  color: string;
}
const TargetButton = ({ onClick, disabled }: { onClick: () => void, disabled:boolean }) => {
    return (
        <IonButton
        onClick={onClick}
        fill="outline"
        disabled={disabled}
        className=""
        >
        Set target
        </IonButton>
    );
    }


export const BodyFatIndicator = ({
  percentage,
  className,
  ranges,
  isTarget,
  myTarget,
  setTarget,
  onTargetChange,
}: BodyFatIndicatorProps) => {
  const [targetPercentage, setTargetPercentage] = useState(myTarget??percentage);
  const delayTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (myTarget) setTargetPercentage(myTarget);
    }, [myTarget]);

  const onCommit = (value: number) => {
    setTargetPercentage(value);
 
    onTargetChange && onTargetChange(value, true);
  }

  const onChange = (value: number) => {
    setTargetPercentage(value);
    onTargetChange && onTargetChange(value, false);
  }

  const minValue = ranges.reduce(
    (min, p) => (p.min < min ? p.min : min),
    ranges[0].min
  );
  const maxValue = ranges.reduce(
    (max, p) => (p.max > max ? p.max : max),
    ranges[0].max
  );
  const getPosition = (value: number) => {
    const position = ((value - minValue) / (maxValue - minValue)) * 100;
    return Math.min(Math.max(position, 0), 100);
  };

  const getCurrentCategory = (value: number) => {
    const range = ranges.find((r) => value >= r.min && value < r.max);
    return range || (value >= 40 ? ranges[3] : ranges[0]);
  };

  const currentCategory = getCurrentCategory(percentage);
  const targetCategory = getCurrentCategory(targetPercentage);

  return (
    <div className={cn("w-full space-y-4", className)}>
                   {<h3 className="text-sm font-medium text-gray-500">Set body fat target</h3>}

      <div className="flex justify-between items-center mb-2">
        <div className="space-y-2 grid grid-cols-3 gap-2">
          {precIndicator(currentCategory, percentage,'Current')}
        {(isTarget||myTarget) && 
          precIndicator(targetCategory, targetPercentage,'Target')}
        {isTarget && <TargetButton onClick={() => setTarget && setTarget(targetPercentage)}  disabled={targetPercentage===myTarget}/>}
        </div>
      </div>

      <div className="relative h-8 rounded-lg overflow-hidden">
        <div className="absolute inset-0 flex">
          {ranges.map((range, index) => (
            <div
              key={range.label}
              style={{
                backgroundColor: range.color,
                width: `${getPosition(range.max) - getPosition(range.min)}%`,
              }}
              className="h-full transition-all duration-300"
            ></div>
          ))}
        </div>
        {/* Current value indicator */}
        <div
          className="absolute top-0 h-full transition-all duration-300"
          style={{
            left: `${getPosition(percentage)}%`,
            transform: "translateX(-50%)",
          }}
        >
          <div className="h-full w-1 bg-black/50 rounded" />
        </div>

        {/* Target value indicator */}

        {(isTarget||myTarget) && (
          <div
            className="absolute top-0 h-full transition-all duration-300"
            style={{
              left: `${getPosition(targetPercentage)}%`,
              transform: "translateX(-50%)",
            }}
          >
            <div className="h-full w-1 bg-primary rounded" />
          </div>
        )}
        
      </div>
      <div className="relative h-8 rounded-lg overflow-hidden">
        <div className="absolute inset-0 flex">
          {ranges.map((range, index) => (
            <div
              key={range.label}
              style={{
                width: `${getPosition(range.max) - getPosition(range.min)}%`,
              }}
              className="h-full transition-all duration-300 text-xs text-gray-500 text-center"
            >
              {range.label}
            </div>
          ))}
        </div>
      </div>

      {isTarget && (
        <div className="pt-6">
          <Slider
            value={[targetPercentage]}
            min={minValue}
            max={maxValue}
            
            step={1}
            
            onValueCommit={(values) => onCommit(values[0])}
            onValueChange={(values) => onChange(values[0])}
          />
        </div>
      )}
    </div>
  );
};
function precIndicator (currentCategory: Range, percentage: number, title: string) {
    return <div className="flex items-center gap-2 mt-2">
        <span className="text-sm font-medium text-gray-500">{title}: </span>
        <span
            className="text-2xl font-semibold px-2 py-0.5 rounded"
            style={{ backgroundColor: currentCategory.color }}
        >
            {percentage}%
        </span>
    </div>;
}

