import { Landmark, LandmarkData, NormalizedLandmark } from "@mediapipe/tasks-vision";
const toleranceDeg = 10;
const calcAngle = (a: NormalizedLandmark, b: NormalizedLandmark): number => {
    const x = a.x - b.x;
    const y = a.y - b.y;
    const rads = Math.atan2(y, x);
    return toDegrees(rads);
}
export const keys = {
    nose: 0,
    rightEye: 2,
    leftEye: 5,
    rightEar: 7,
    leftEar: 8,
    rightShoulder: 11,
    leftShoulder: 12,
    rightElbow: 13,
    leftElbow: 14,
    rightWrist: 15,
    leftWrist: 16,
    rightHip: 23,
    leftHip: 24,
    rightKnee: 25,
    leftKnee: 26,
    rightAnkle: 27,
    leftAnkle: 28,
};
export const keyNames = Object.keys(keys);
export const toIndex = (key: number) => {
    return Object.values(keys).indexOf(key);
}
export const toKey = (index: number) => {
    return keyNames[index];
}
export const toOriginalIndex = (index: number) => {
    return Object.values(keys)[index];
}

interface TargetAngles {
    rightArm: number,
    rightArmLower: number,
    leftArm: number,
    leftArmLower: number,
    rightLeg: number,
    leftLeg: number
}
export const targetAnglesFrontPose = {
    rightArm: -60,
    rightArmLower: -60,
    leftArm: -120,
    leftArmLower: -120,
    rightLeg: -85,
    leftLeg: -95
} as TargetAngles

export const targetAnglesSidePose = {
    rightArm: -95,
    rightArmLower: -95,
    //leftArm: -80,
    //leftArmLower: -80,
    rightLeg: -90,
    leftLeg: -90

} as TargetAngles

export const isOkLandmark = (landmarks: NormalizedLandmark[], index?: number, marginW=0.05,marginH=0.05, shouldBeVisible?: boolean): boolean => {
    //const ogi = toOriginalIndex(index);
    if (index === undefined) {
        return false;
    }
    const lm = landmarks[index];
    if (lm.visibility < 0.7 && shouldBeVisible) {
        return false;
    }
    return lm.x > marginW && lm.x < 1 - marginW && lm.y > marginH && lm.y < 1 - marginH;
    //return true

}
export const angleForLine = (landmarks: NormalizedLandmark[], index: number): number => {
    //const ogi = toOriginalIndex(index);
    const lineIndex = connections[index];
    if (lineIndex) {
        const angle = calcAngle(landmarks[lineIndex.start], landmarks[lineIndex.end]);
        return angle;
    }
    return 0;

}
const toDegrees = (radians: number) => {
    return radians * (180 / Math.PI);
}
const toRadians = (degrees: number) => {
    return degrees * (Math.PI / 180);
}
export const isErrorLine = (landmarks: Landmark[],  data: LandmarkData, targetAngles: TargetAngles,pose:'front'|'right', mirrored:boolean =true): boolean => {
    //const ogi = toOriginalIndex(index);
    const lineIndex = data.index 
    if (lineIndex !== undefined) {
        const line= connections[lineIndex];
        const isRightArmUpper = lineIndex === 0;
        const isLeftArmUpper = lineIndex=== 2;
        const isLeftArmLower = lineIndex === 3;
        const isRightArmLower = lineIndex === 1;
        const isRightLegUpper = lineIndex === 4;
        const isLeftLegUpper = lineIndex === 6;
        const isLeftLegLower = lineIndex === 7;
        const isRightLegLower = lineIndex === 5;
        if (!mirrored) {
            // flip the left and right angles for arm and legs 
            targetAngles = {
                rightArm: targetAngles.leftArm,
                leftArm: targetAngles.rightArm,
                rightLeg: targetAngles.leftLeg,
                leftLeg: targetAngles.rightLeg,
                rightArmLower: targetAngles.leftArmLower,
                leftArmLower: targetAngles.rightArmLower

            }
        }
        if (isRightArmUpper) {
            const angle_upper = angleForLine(landmarks, 0);
            return Math.abs(angle_upper - targetAngles.rightArm) > toleranceDeg 

        }
        if (isLeftArmUpper) {

            const angle_upper = angleForLine(landmarks, 2);

            return Math.abs(angle_upper - targetAngles.leftArm) > toleranceDeg ;
        }

        if (isLeftArmLower) {
            const angle_upper = angleForLine(landmarks, 3);
            const angle_lower = angleForLine(landmarks, lineIndex);
            if ( Math.abs(angle_lower - targetAngles.leftArmLower) > toleranceDeg || pose==='front' && Math.abs(angle_upper - angle_lower) > toleranceDeg) {
                return true;
            }
            return false;
        }

        if (isRightArmLower) {
            const angle_upper = angleForLine(landmarks, 1);
            const angle_lower = angleForLine(landmarks, lineIndex);
            if ( Math.abs(angle_lower - targetAngles.rightArmLower) > toleranceDeg || pose==='front' && Math.abs(angle_upper - angle_lower) > toleranceDeg) {
                return true;
            }
            return false;
        }
        if (isRightLegUpper) {
            const angle_upper = angleForLine(landmarks, lineIndex);
            if (Math.abs(angle_upper - targetAngles.rightLeg) > toleranceDeg) {
                return true;
            }
            return false;
        }
        if (isLeftLegUpper) {
            const angle_upper = angleForLine(landmarks, 6);
            return Math.abs(angle_upper - targetAngles.leftLeg) > toleranceDeg;
        }
        if (isLeftLegLower) {
            const angle_upper = angleForLine(landmarks, 6);
            const angle_lower = angleForLine(landmarks, lineIndex);
            if (Math.abs(angle_lower - targetAngles.leftLeg) > toleranceDeg || Math.abs(angle_upper - angle_lower) > toleranceDeg) {
                return true;
            }
            return false;
        }
        if (isRightLegLower) {
            const angle_upper = angleForLine(landmarks, 4);
            const angle_lower = angleForLine(landmarks, lineIndex);
            if (Math.abs(angle_lower - targetAngles.leftLeg) > toleranceDeg || Math.abs(angle_upper - angle_lower) > toleranceDeg) {
                return true;
            }
            return false;
        }


        if (landmarks[line.start].visibility < 0.5 || landmarks[line.end].visibility < 0.5) {
            return true;
        }
        return false;
    }
    return true;

}

export const connections = [
    { start: keys.rightShoulder, end: keys.rightElbow },
    { start: keys.rightElbow, end: keys.rightWrist },
    { start: keys.leftShoulder, end: keys.leftElbow },
    { start: keys.leftElbow, end: keys.leftWrist },
    { start: keys.rightHip, end: keys.rightKnee },
    { start: keys.rightKnee, end: keys.rightAnkle },
    { start: keys.leftHip, end: keys.leftKnee },
    { start: keys.leftKnee, end: keys.leftAnkle },
    // {start: keys.rightEar, end: keys.nose},



].map((c) => { return { start: toIndex(c.start), end: toIndex(c.end) } });

export const visibleLandmarksFront = [keys.nose, keys.rightEye, keys.leftEye, keys.rightEar, keys.leftEar, keys.rightShoulder, keys.leftShoulder, keys.rightElbow, keys.leftElbow, keys.rightWrist, keys.leftWrist, keys.rightHip, keys.leftHip, keys.rightKnee, keys.leftKnee, keys.rightAnkle, keys.leftAnkle];
export const visibleLandmarksSide_o = [keys.nose, keys.rightEye,  keys.rightEar,  keys.rightShoulder, keys.rightElbow,  keys.rightWrist,  keys.rightHip, keys.leftHip, keys.rightKnee, keys.leftKnee, keys.rightAnkle, keys.leftAnkle];

export const visibleLandmarksSide = [keys.nose, keys.rightEye,  keys.rightEar,  keys.rightShoulder, keys.rightElbow,  keys.rightWrist,  keys.rightHip,  keys.rightKnee,  keys.rightAnkle, ];
export const isInside = (landmarks: NormalizedLandmark[], marginW=0.05, marginH=0.05): boolean => {
    if (landmarks === undefined || landmarks.length === 0) {
        return false;
    }
    return landmarks.every((lm) => {
        return lm.visibility>0.0 &&  lm.x > marginW && lm.x < 1 - marginW && lm.y > marginH && lm.y < 1 - marginH;
    }
    );
}

export function getFacing(landmarks: NormalizedLandmark[]): 'front' | 'right' | 'left' {
    const nose = landmarks[0];
    const leftEye = landmarks[5];
    const rightEye = landmarks[2];
    const leftEar = landmarks[8];
    const rightEar = landmarks[7];
    //console.log("EYE ", leftEye.visibility, rightEye.visibility)
    const rightSideVisible = rightEye.visibility > 0.5 && rightEar.visibility > 0.5;
    const leftSideVisible = leftEye.visibility > 0.5 && leftEar.visibility > 0.5;

    
    // use visibility to determine if the landmark is visible
    let face: 'front' | 'left' | 'right' = 'front';
    if (rightSideVisible && !leftSideVisible) {
        face = 'right';
    }
    if (leftSideVisible && !rightSideVisible) {
        face = 'left';
    }


    return face;


}

// is moving if indiviual landmarks are moving in the time series 
export const isMoving = (landmarks: NormalizedLandmark[][], avgLandMarks:NormalizedLandmark[]): boolean => {
    const threshold = 0.05;
    const diffs = landmarks.map((lm, i) => {

        const compareTo = (landmark: NormalizedLandmark[]) => {
            return landmark.map((data, j) => {
                let lastData = avgLandMarks[j];
                if (lastData && data) {
                    if (data.visibility < 0.8 || lastData.visibility < 0.8) {
                        return 0;
                    }
                    const diff = Math.sqrt((data.x - lastData.x) ** 2 + (data.y - lastData.y) ** 2 + (data.z - lastData.z) ** 2);
                    return diff;
                }
                return 0;
            });
        }
        return compareTo(lm);
    }
    )
   const maxDiff = diffs.flat().reduce((acc, val) => {
        return Math.max(acc, val);
    }
    , 0);
    return maxDiff > threshold;
}

