import { female } from "ionicons/icons";
import { ObjectModel } from "../../../common/types";
import { AuthProvider, getClient, getMeasurements, getModel, getStarParams, generateModel, setTarget } from "../../utils/http-service";
import { ClientData, MeasureData, ObjectModelData, StarParams } from "../../utils/interfaces";
import { t } from "numeric";

export interface MeasurementPoint {
    id: string;
    date: Date;
    value: number;
};

export interface DashBoardComponentProps {
    clientHolder: ClientDataHolder;
    clientData: ClientData;
}

export class ClientDataHolder {

    ID: string;
    auth: AuthProvider;
    data?: ClientData;
    loadedMeasurements: boolean;
    loadedModels: boolean;
    clientLoaded: boolean;
    measurements: MeasureData[];
    objectModels: ObjectModelData[];
    params?: StarParams;
    setClientData: (data: ClientData) => void;
    constructor(id: string, auth: AuthProvider, setClientData: (data: ClientData) => void) {
        this.ID = id;
        this.auth = auth;
        this.loadedMeasurements = false;
        this.loadedModels = false;
        this.clientLoaded = false;
        this.measurements = [];
        this.objectModels = [];
        this.setClientData = setClientData;

        //this.params = undefined;
    }
    getStarParamsLatest = async () => {
        if (!this.params) {
            await this.loadStarParams();
        }
        return this.loadStarParams();
    }
    loadStarParams = async () => {
        if (!this.data) {
            await this.loadClientData();
        }
        if (!this.measurements) {
            await this.loadMeasurements();
        }
        const taskID = this.measurements[this.measurements.length - 1].taskID;

        const params = await getStarParams(this.ID, taskID, this.auth)
        this.params = params;
        return params;
    }
    getEstimation = async (percentage: number) => {
        if (!this.params) {
            await this.loadStarParams();
        }
        const clientData = await this.getClient();
        const taskID = this.measurements[this.measurements.length - 1].taskID;
        const result = await generateModel(taskID, clientData.gender
            , percentage, this.params?.shape || [], this.auth);
        return result;
    }

    getClient = async (force?: boolean): Promise<ClientData> => {
        if (force) {
            return this.loadClientData();
        }
        
        const data =  this.data || this.loadClientData();
        this.setClientData(await data)
        return data
    }
    getMeasurements = async (unit: 'cm' | 'mm' = 'cm'): Promise<MeasureData[]> => {
        const clientData = await this.getClient();

        let measurements = this.loadedMeasurements ? this.measurements : await this.loadMeasurements();
        if (unit === 'mm') {
            measurements = measurements.map(m => {
                const measure = m.measurements;
                return {
                    ...m,
                    measurements: {
                        ...measure,
                        head: measure.head * 10,
                        neck: measure.neck * 10,
                        chest: measure.chest * 10,
                        waist: measure.waist * 10,
                        buttocks: measure.buttocks * 10,
                        leg_inner: measure.leg_inner * 10,
                        arm_outer: measure.arm_outer * 10,
                        height: measure.height * 10,
                        leg_circ: measure.leg_circ ? measure.leg_circ * 10 : undefined,
                        arm_circ: measure.arm_circ ? measure.arm_circ * 10 : undefined,
                        waist_female: measure.waist_female ? measure.waist_female * 10 : undefined
                    }
                }
            });
        }

        // Add body fat calculation
        measurements = measurements.map(m => {
            const body_fat = calculateBodyFat(m.measurements, clientData.gender);
            return {
                ...m,
                measurements: {
                    ...m.measurements,
                    body_fat
                }
            };
        });

        return measurements;
    }
    getModels = async (): Promise<ObjectModel[]> => {
        const models = await this.getObjectModels();
        const measurements = await this.getMeasurements('mm');
        return models.filter(m => m.taskID && measurements.some(meas => meas.taskID === m.taskID)).map((m) => toObjectModel(m, measurements.find(meas => meas.taskID === m.taskID) as MeasureData));
    }
    getObjectModels = async (): Promise<ObjectModelData[]> => {
        return this.loadedModels ? this.objectModels : this.loadModels();
    }
    setTarget = async (percentage: number):Promise<ClientData|undefined> => {
        if (!this.data) {
            throw new Error('Client data not loaded');
        }
        
        console.log('setTarget', this.ID, percentage);
        const taskID = this.measurements[this.measurements.length - 1].taskID;
        console.log('taskID', taskID, percentage);
        const targetMeasure: MeasureData['measurements'] = {body_fat: percentage, head: 0, neck: 0, chest: 0, waist: 0, buttocks: 0, leg_inner: 0, arm_outer: 0, height: 0}
        let res = await setTarget(this.ID,taskID, targetMeasure , this.auth);
        if (res.ok) {
            const target:MeasureData =  await res.json()
            if (this.data) {
                this.data = {...this.data, target: target};
            }
            this.setClientData(this.data);
            return this.data;
        }
    }

    loadModels = async () => {
        const measurements = await this.getMeasurements('mm');
        const localModelsP = measurements.map(async (m) => {
            const d = await getModel(this.ID, m.taskID, this.auth);
            return { obj: d, createdAt: m.createdAt, taskID: m.taskID, client: this.ID, ID: m.ID } as ObjectModelData;
        });
        const localModels = await Promise.all(localModelsP)
        this.loadedModels = true;
        this.objectModels = localModels
        return this.objectModels;
    }
    loadMeasurements = async (): Promise<MeasureData[]> => {
        this.measurements = await getMeasurements(this.ID, this.auth);
        const d = this.data || await getClient(this.ID, this.auth);
        this.measurements = this.measurements.map(m => normalizeToHeight(m, d.height));
        this.loadedMeasurements = true;
        return this.measurements;
    }
    loadClientData = async (): Promise<ClientData> => {
        this.data = await getClient(this.ID, this.auth);
        this.clientLoaded = true;
        return this.data;
    }
    load = async () => {
        await this.loadClientData();
        await this.loadMeasurements();
        await this.loadModels();
    }
    isLoaded = () => {
        return this.loadedModels && this.loadedMeasurements && this.clientLoaded;
    }
}

const getValue = (data: MeasureData, measurementType: string) => {
    const m = data.measurements;
    switch (measurementType) {
        case 'neck':
            return m.neck;
        case 'weight':
            return m.weight;
        case 'waist':
            return m.waist;
        case 'buttocks':
            return m.buttocks;
        case 'height':
            return m.height;
        case 'chest':
            return m.chest;
        default:
            return 0;
    }
}
// 'neck'|'weight'|'waist'| 'height'| 'buttocks'

const getMeasurementsData = async (clientId: string, measurementType: string, auth: AuthProvider): Promise<MeasurementPoint[]> => {
    // fetch data from server
    const client = await getClient(clientId, auth);
    if (!client) {
        return Promise.resolve([]);
    }
    const height = client.height;
    if (clientId.length > 1) {
        const measurements = await getMeasurements(clientId, auth);
        const res: MeasurementPoint[] = measurements.map(m => normalizeToHeight(m as MeasureData, height)).map(m => {
            return { id: m.ID || ' ', date: new Date(m.createdAt), value: getValue(m, measurementType) || 0 };
        });
    }
    const measurements = await getMeasurements(clientId, auth);
    if (!client) {
        return Promise.resolve([]);
    }
    measurementType = measurementType as keyof MeasureData['measurements'];

    const res: MeasurementPoint[] = measurements.map(m => normalizeToHeight(m, height)).map(m => {
        return { id: m.ID || ' ', date: new Date(m.createdAt), value: getValue(m, measurementType) || 0 };
    });
    return res;
}
const normalizeToHeight = (measureData: MeasureData, heightInput: number): MeasureData => {
    const measure = { ...measureData.measurements };
    let scale = (heightInput * 10) / measure.height;
    measure.head = measure.head / 10 * scale;
    measure.neck = measure.neck / 10 * scale;
    measure.chest = measure.chest / 10 * scale;
    measure.waist = measure.waist / 10 * scale;
    measure.buttocks = measure.buttocks / 10 * scale;
    measure.leg_inner = measure.leg_inner / 10 * scale;
    measure.arm_outer = measure.arm_outer / 10 * scale;
    measure.height = measure.height / 10 * scale;
    measure.leg_circ = measure.leg_circ ? measure.leg_circ / 10 * scale : undefined;
    measure.arm_circ = measure.arm_circ ? measure.arm_circ / 10 * scale : undefined;
    measure.waist_female = measure.waist_female ? measure.waist_female / 10 * scale : undefined;
    return { ...measureData, measurements: measure };
}
const toObjectModel = (model: ObjectModelData, measurements: MeasureData): ObjectModel => {
    return {
        id: model.ID,
        createdAt: model.createdAt + '',
        obj: model.obj,
        visibleEffects: [0],
        showMeasurement: '',
        measurements: measurements.measurements
    }
}
const getBfValues = (measurements: MeasureData['measurements'], gender:'male'|'female') => {
    const waist = ((gender===female && measurements.waist_female) ? measurements.waist_female : measurements.waist) /2.54;
    
    const neck = measurements.neck / 2.54;
    const height = measurements.height/ 2.54;

    const hip = measurements.buttocks / 2.54;
    return { waist , neck, height, hip };

}

// Add this function to calculate body fat percentage
const calculateBodyFat = (measurements: MeasureData['measurements'], gender: 'male' | 'female'): number => {
    // Example calculation logic, replace with actual logic
    let { waist, neck, height, hip } = getBfValues(measurements, gender);
    if (gender === "male") {
        return (
          86.01 * Math.log10(waist - neck) - 70.041 * Math.log10(height) + 36.76
        );
      } else {
        return (
          163.205 * Math.log10(waist + hip - neck) -
          97.684 * Math.log10(height) -
          78.387
        );
        }
};

//export default getMeasurementsData;
