import { IonContent, IonPage } from "@ionic/react";
import { useState } from "react";
import { CameraOverlay } from "../components/CameraOverlay";
import { useParams } from "react-router";
import { Permissions } from "../components/Permissions";
const CamTest: React.FC = () => {
  const pose = useParams<{ pose: "front" | "right" }>().pose;
  const [permission, setPermission] = useState(false);

  return (
    <IonPage className="z-50">
      <IonContent fullscreen>
      <Permissions updated={(granted: any) => setPermission(granted.gyro)}></Permissions>

        
        {permission && <CameraOverlay
          setUploaded={() => {}}
          id={"123"}
          pose={pose|| "front"}
          gender={"male"}
          uploadPhoto={(data: string) => {
            // read jpeg data
            const b64 = data.split(",")[1];

            //console.log(b64);
            // check size of the image
            const image = new Image();
            image.src = data;
            image.onload = function () {
              const jaja = window.confirm(
                "Image size:  w: " + image.width + ", h: " + image.height
              );
              if (jaja) {
                // download the image to user device
                const a = document.createElement("a");
                a.href = data;
                a.download = "image.jpg";
                a.click();
              }
            };
          }}
        />}
      </IonContent>
    </IonPage>
  );
};

export default CamTest;
