export interface BodyFatCategory {
    cat: string;
    min: number;
    max: number;
    color?: string;
}


export const getBodyFatTable = (gender: 'male' | 'female', age: number): BodyFatCategory[] => {

    const categories = [
        {
            ageRange: [18, 39],
            gender: 'male',
            categories: [
                { cat: "Underfat", min: 0, max: 9 },
                { cat: "Healthy", min: 9, max: 20 },
                { cat: "Overfat", min: 20, max: 25 },
                { cat: "Obese", min: 25, max: 40 },
            ]
        },
        {
            ageRange: [40, 59],
            gender: 'male',
            categories: [
                { cat: "Underfat", min: 0, max: 11 },
                { cat: "Healthy", min: 11, max: 21 },
                { cat: "Overfat", min: 21, max: 27 },
                { cat: "Obese", min: 27, max: 40 },
            ]
        },
        {
            ageRange: [60, 79],
            gender: 'male',
            categories: [
                { cat: "Underfat", min: 0, max: 13 },
                { cat: "Healthy", min: 13, max: 22 },
                { cat: "Overfat", min: 22, max: 30 },
                { cat: "Obese", min: 30, max: 45 },
            ]
        },
        {
            ageRange: [18, 39],
            gender: 'female',
            categories: [
                { cat: "Underfat", min: 0, max: 21 },
                { cat: "Healthy", min: 21, max: 33 },
                { cat: "Overfat", min: 33, max: 39 },
                { cat: "Obese", min: 39, max: 45 },
            ]
        },
        {
            ageRange: [40, 59],
            gender: 'female',
            categories: [
                { cat: "Underfat", min: 0, max: 23 },
                { cat: "Healthy", min: 23, max: 35 },
                { cat: "Overfat", min: 35, max: 40 },
                { cat: "Obese", min: 40, max: 45 },
            ]
        },
        {
            ageRange: [60, 79],
            gender: 'female',
            categories: [
                { cat: "Underfat", min: 0, max: 24 },
                { cat: "Healthy", min: 24, max: 38 },
                { cat: "Overfat", min: 38, max: 44 },
                { cat: "Obese", min: 44, max: 50 },
            ]

        }
    ]
    
    const category:BodyFatCategory[] = categories.find((cat) => {
        return cat.gender === gender && cat.ageRange[0] <= age && cat.ageRange[1] >=age} )?.categories as BodyFatCategory[];
    if (!category) {
        return [];
    }
  /*   const underColor = "bg-blue-300";
    const healthyColor = "bg-lime-300";
    const overColor = "bg-yellow-300";
    const obeseColor = "bg-red-300"; */
    const underColor = "#D3E4FD";
    const healthyColor = "#E2F5D3";
    const overColor = "#FFE29F";
    const obeseColor = "#FFDEE2";
    
    category[0].color = underColor;
    category[1].color = healthyColor;
    category[2].color = overColor;
    category[3].color = obeseColor;
    return category;

}