import { useState } from "react";
import { Card } from "./ui/card";
import { ArrowUp, ArrowDown, ChevronDown, ChevronUp } from "lucide-react";
import { ProgressChart } from "./ProgressChart";
import { BodyFatIndicator, Range } from "./BodyFatIndicator";
import { MeasurementPoint } from "../../pt/dashboard/data/data";

interface MeasurementCardProps {
  title: string;
  value: number;
  unit: string;
  change?: number;
  lastUpdate?: string;
  ranges ?: Range[];
  isTarget?: boolean;
  myTarget?: number;
  setTarget?: (value: number) => void;
  values?: MeasurementPoint[];
  onTargetChange?: (value: number, commit:boolean) => void;
}

const formatDate = (date: Date) => {
    return date.toLocaleDateString("en-GB", {
      month: "numeric",
      year: "2-digit",
      day: "numeric",
    });
  };

export const MeasurementCard = ({
    title,
    value,
    unit,
    change,
    lastUpdate,
    ranges,
    values,
    isTarget,
    myTarget,
    setTarget,
    onTargetChange,
  }: MeasurementCardProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const hasChange = typeof change !== "undefined";
    const isPositive = hasChange && change > 0;
    const isNegative = hasChange && change < 0;
    const isExpandible = values && values.length > 1;
    const isBodyFat = title.toLowerCase().includes("body");
    
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation(); // Prevent event bubbling
      if (!isExpandible) return;
      setIsExpanded(!isExpanded);
    };
    const toDataPoints = (values?: MeasurementPoint[]) => {
        if (!values) return [];

        return values.map((point) => ({
            date: formatDate(point.date),
            value: point.value,
        }));
    };
  
    return (
      <Card 
        className={`p-6 backdrop-blur-lg bg-card/80 border border-gray-200/20 animate-fade-in hover:shadow-lg transition-all duration-300 ${
          isExpanded ? 'md:col-span-3 lg:col-span-2' : ''
        }`}
      >
        <div className="flex flex-col space-y-4">
          <div 
            className="flex justify-between items-center cursor-pointer" 
            onClick={handleClick}
          >
           {!isTarget && <h3 className="text-sm font-medium text-gray-500">{title}</h3>}
            {(isExpandible) && (isExpanded ? (
              <ChevronUp className="w-4 h-4 text-gray-500" />
            ) : (
              <ChevronDown className="w-4 h-4 text-gray-500" />
            ))}
          </div>
  
          {isBodyFat ? (
           /*  isExpanded  ? (
              ranges &&   <BodyFatTarget currentPercentage={value} className="mt-4" ranges= {ranges}  />
            ) : */ (
                 ranges && <BodyFatIndicator myTarget={myTarget} setTarget={setTarget} percentage={value} ranges={ranges} expanded={isExpanded} onTargetChange={onTargetChange}  className="mt-4" isTarget = {isTarget} />
            )
          ) : (
            <div className="flex items-end justify-between">
              <div className="flex items-baseline space-x-2">
                <span className="text-2xl font-semibold">{value}</span>
                <span className="text-sm text-gray-500">{unit}</span>
              </div>
              {hasChange && (
                <div
                  className={`flex items-center space-x-1 text-sm ${
                    isPositive ? "text-green-500" : "text-red-500"
                  }`}
                >
                {isExpandible && (
                  <>
                    {isPositive ? (
                      <ArrowUp className="w-4 h-4" />
                    ) : (
                      <ArrowDown className="w-4 h-4" />
                    )}
                    <span>{Math.abs(change)}</span>
                  </>
                )}
                </div>
              )}
            </div>
          )}
  
          {lastUpdate && (
            <p className="text-xs text-gray-400">{`Updated ${lastUpdate}`}</p>
          )}
          
          {isExpanded && (
            <div className="mt-6 h-[300px] animate-fade-in">
              <ProgressChart
                data={toDataPoints(values)}
                myTarget={myTarget}
                title={`${title} History`}
                unit={unit}
              />
            </div>
          )}
        </div>
      </Card>
    );
  };